import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {faInfoCircle, faXmark, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-custom-input-german-decimal',
  templateUrl: './german-decimal-anydigits.component.html',
  styleUrls: ['./german-decimal-anydigits.component.scss']
})
export class GermanDecimalAnydigitsComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() label: any;
  @Input() min: string | number | Date | null;
  @Input() max: string | number | Date | null;
  @Input() customFormGroup: UntypedFormGroup;
  @Input() noteText: string;
  @Input() showMaxLength = false;
  @Input() maxLength = 1;
  @Input() maxlength = 4096;   // maxlength limit for number of chars in standard html input div
  @Input() pattern = '.*';
  @Input() placeholder = '';
  @Input() noMb = false;
  // visually!! hides the label. It is still avail. for a screen reader
  @Input() hideLabel = false;
  @Input() type = 'text';
  @Input() step = "";
  @Input() acceptFormats : string;

  @Input() readonly = false;
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() infoText: string;
  @Input() extra_field_text: string; //if set, extra field ist displayed
  @Input() extra_field_icon: IconDefinition;

  @Input() numDigits: number = 2;

  @Output() onchange = new EventEmitter();
  @Output() onClear = new EventEmitter();

  faInfo = faInfoCircle;
  faX = faXmark;

  currentLength = 0;
  destroySub$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.customFormGroup
      .get(this.id)
      .valueChanges
      .pipe(takeUntil(this.destroySub$))
      .subscribe((value) => {
        if (value) {
          this.currentLength = value.length;
        } else {
          this.currentLength = 0;
        }
      });
  }

  c(id: string) {
    return this.customFormGroup?.get(id);
  }

  triggerOnChange($event) {
    this.onchange.emit($event);
  }

  triggerOnClear($event) {
    this.onClear.emit($event);
  }

  ngOnDestroy(): void {
    this.destroySub$.next();
    this.destroySub$.complete();
  }

    protected readonly environment = environment;
}
