<nav class="navbar navbar-expand-lg navbar-dark mb-3 colorednavbar">
  <div class="container-fluid">
    <button class="navbar-toggler" style="margin-right:5px !important;" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller']">
          <a class="nav-link textnavbar" [routerLink]="['/pages/home']">
            <fa-icon class="homeIcon" [icon]="faHouse"></fa-icon>
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller'] && moduleEnabled['antragTicket']"
            data-cy="nav-item-antraege">
          <a class="nav-link textnavbar"
             [routerLink]="['/pages/ticket-table']"
             data-cy="nav-item-antraege-link"
          >
            {{existsSessionStorageTextbausteine?
              textbausteinService.printTextbausteinByKey( KeyString.ANTRAEGE_VERWALTEN_MENUE, ('HOME.LINK_TICKET_TABLE'|translate) ):('HOME.LINK_TICKET_TABLE'|translate)}}
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller'] && moduleEnabled['fahrkosten']"
            data-cy="nav-item-fahrkosten">
          <a class="nav-link textnavbar" [routerLink]="['/pages/fahrkosten']" data-cy="nav-item-fahrkosten-link">
            {{existsSessionStorageTextbausteine?
              textbausteinService.printTextbausteinByKey( KeyString.FAHRKOSTEN_VERWALTEN_MENUE, ('HOME.LINK_FAHRKOSTEN_TABLE'|translate) ):('HOME.LINK_FAHRKOSTEN_TABLE'|translate)}}
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller']"
            data-cy="nav-item-schueler">
          <a class="nav-link textnavbar" [routerLink]="['/pages/schueler-table']" data-cy="nav-item-schueler-link">
            {{existsSessionStorageTextbausteine?
              textbausteinService.printTextbausteinByKey( KeyString.SCHUELER_VERWALTEN_MENUE, ('HOME.LINK_SCHUELER_TABLE'|translate) ):('HOME.LINK_SCHUELER_TABLE'|translate)}}
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-konfiguration">
          <a class="nav-link textnavbar" [routerLink]="['/pages/config']" data-cy="nav-item-konfiguration-link">{{"HOME.LINK_KONFIGURATION" | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-textbausteine">
          <a class="nav-link textnavbar" [routerLink]="['/pages/textbausteine']" data-cy="nav-item-textbausteine-link">{{"HOME.LINK_TEXTBAUSTEINE" | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-schulen">
          <a class="nav-link textnavbar" [routerLink]="['/pages/schulen']" data-cy="nav-item-schulen-link">{{"HOME.LINK_SCHULEN" | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-schultypen">
          <a class="nav-link textnavbar" [routerLink]="['/pages/schultypen']" data-cy="nav-item-schultypen-link">{{"HOME.LINK_SCHULTYPEN" | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
