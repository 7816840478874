import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() id: string;
  @Input() label = '';
  @Input() customFormGroup: UntypedFormGroup;
  @Input() noteText: string;
  @Input() placeholder = '';
  @Input() items: any[];
  @Input() bindLabel: string;
  @Input() customStringTemplate: TemplateRef<any>;
  @Input() bindValue: string;
  @Input() multiple = true;
  @Input() readonly = false;
  @Input() infoText: string;
  @Input() clearable = true;
  @Input() searchable = true;
  @Input() notfoundtext = "Nichts gefunden";
  @Input() loading= false;
  @Input() loadingText= "Lädt...";
  @Input() hideSelected = false;
  @Input() infoTextBelowInput: string;

  @Output() onchange = new EventEmitter();
  @Output() onsearch = new EventEmitter();
  @Output() onScrollToEnd = new EventEmitter();

  faInfo = faInfoCircle;

  isInvalid() {
    return (
      this.customFormGroup.get(this.id).invalid &&
      this.customFormGroup.get(this.id).touched
    );
  }

  triggerOnChange($event) {
    this.onchange.emit($event);
  }

  triggerSearch($event) {
    this.onsearch.emit($event);
  }

  triggerScrollToEnd($event) {
    this.onScrollToEnd.emit($event);
  }

    protected readonly environment = environment;
}
