import {Component, OnInit} from '@angular/core';
import {catchError} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {
  ApplicationPropertiesService
} from 'src/app/@core/services/application-properties/application-properties.service';
import {ActivatedRoute, Router} from "@angular/router";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {FieldConfigService} from "../../../@core/services/field-config/field-config.service";
import {Land} from "../../../models/land/Land";
import {TranslateService} from "@ngx-translate/core";
import {ApplicationPropertiesDTO} from "../../../models/applicationproperties/ApplicationPropertiesDTO";
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {GeschlechtHelperService} from "../../../@core/utils/forms/geschlecht-helper.service";
import {KeyString} from "../../../models/textbaustein/KeyString";
import {ModuleConfigService} from "../../../@core/services/module-config/module-config.service";
import {ConfigService} from "../../../@core/config/services/config.service";

@Component({
  selector: 'app-fahrkosten-detail-parent',
  templateUrl: './fahrkosten-detail-parent.component.html',
  styleUrls: ['./fahrkosten-detail-parent.component.scss']
})
export class FahrkostenDetailParentComponent implements OnInit {

  title = '';
  subtitle='';

  fahrkostenId: string;
  hasSachbearbeiterBackend: boolean = false;
  fieldConfig;

  benutzer: BenutzerDTO;
  benutzerRoles: Array<string>;

  subscriptionTranslate: Subscription;

  isFahrkostenSonderfallModuleEnabled: boolean = false;
  optionTyp = [];
  optionErmaessigung = [];
  optionGeschlecht = [];
  optionWegtyp = [];
  optionSonderfall = [];
  optionBefoerderung = [];
  landList = [];
  portalUrl = '';

  constructor(
    public translateService: TranslateService,
    private applicationPropertiesService: ApplicationPropertiesService,
    private route: ActivatedRoute,
    private router: Router,
    private customToastService: CustomToastService,
    public textbauSteinService: TextbausteinService,
    private fieldConfigService: FieldConfigService,
    private geschlechtHelperService: GeschlechtHelperService,
    private moduleConfigService: ModuleConfigService,
    private configService: ConfigService
  ) {
    this.isFahrkostenSonderfallModuleEnabled = this.moduleConfigService.isModuleEnabled('fahrkostenSonderfall');

    if(this.configService.featureConfig.kommunalportalAuth)
      this.portalUrl = sessionStorage.getItem('portalUrl');
  }

  ngOnInit(): void {

    this.checkForSBBackend()
      .then(() => {
        this.title = this.textbauSteinService.printTextbausteinByKey( KeyString.SUBTITLE_FAHRKOSTEN,
          this.translateService.instant('FAHRKOSTEN-DETAIL.TITLE'));
        this.loadFieldConfig();
        this.setBenutzer();
        this.fahrkostenId = this.route.snapshot.paramMap.get('id');
        this.initHardcodedLists();
        this.initRequiredFields();
      })
      .catch((err) => {console.log(err)});

  }

  ngOnDestroy() {
    this.subscriptionTranslate.unsubscribe();
  }

  initRequiredFields(){
    let allFormFields = this.fahrkostenFormFields;
    allFormFields.forEach((varName) => {
      let field = this.fieldConfigService.getFieldFromFieldConfig(varName, this.fieldConfig);
      if(!(field)) {
        field = {field: varName, visible: true, required: false, label: null, module: 'fahrkosten'};
      }
      if( field.visible===true || this.alwaysVisible.includes(varName)){
        this.visibleFields = [...this.visibleFields,varName];
        if(!this.neverValidate.includes(varName) &&
          (this.alwaysValidateIfVisible.includes(varName) || field.required===true)){
            this.requiredFields = [...this.requiredFields,varName];
        }
      }
    });
  }

  initHardcodedLists(): void {
    this.subscriptionTranslate = this.translateService.get('dummyTranslation').subscribe(()=>{
      this.optionTyp = [{id: null, name:"-"},
        {id:"NEUANTRAG", name: this.translateService.instant("TICKETDETAIL.TYP.NEUANTRAG")},
        {id:"FOLGEANTRAG", name: this.translateService.instant('TICKETDETAIL.TYP.FOLGEANTRAG')},
        {id:"AENDERUNGSANTRAG_UMZUG", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_UMZUG") },
        {id:"AENDERUNGSANTRAG_NAMENSAENDERUNG", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_NAMENSAENDERUNG") },
        {id:"AENDERUNGSANTRAG_HALTESTELLENAENDERUNG", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_HALTESTELLENAENDERUNG") },
        {id:"AENDERUNGSANTRAG_SCHULWECHSEL", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_SCHULWECHSEL") },
        {id:"AENDERUNGSANTRAG_BANKDATEN", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_BANKDATEN") },
        {id:"KUENDIGUNG", name: this.translateService.instant("TICKETDETAIL.TYP.KUENDIGUNG") }
      ];
      this.optionErmaessigung = [{id: null, name: this.translateService.instant("TICKETDETAIL.ERMAESSIGUNG.KEINE") },
        {id:"ERMAESSIGT", name: this.translateService.instant("TICKETDETAIL.ERMAESSIGUNG.ERMAESSIGT") },
        {id:"ERLASSEN", name: this.translateService.instant("TICKETDETAIL.ERMAESSIGUNG.ERLASSEN") }
      ];
      this.optionGeschlecht = this.geschlechtHelperService.getGeschlechtOptionList();
      this.optionWegtyp = [{id: null, name: "-" },
        {id:"SCHULWEG", name: this.translateService.instant("TICKETDETAIL.WEGTYP.SCHULWEG") },
        {id:"PRAKTIKUMSWEG", name: this.translateService.instant("TICKETDETAIL.WEGTYP.PRAKTIKUMSWEG") },
        {id:"BEIDES", name: this.translateService.instant("TICKETDETAIL.WEGTYP.BEIDES") }
      ];

      this.optionSonderfall = [{value:null, name: this.translateService.instant("TICKETDETAIL.SONDERFALL.NICHT_AUSGEWAEHLT") },
        {value:"SCHWERBEHINDERUNG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SCHWERBEHINDERUNG") },
        {value:"INKLUSION_BESONDERE_FOERDERUNG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.INKLUSION_BESONDERE_FOERDERUNG") },
        {value:"ERKRANKUNG_VERLETZUNG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.ERKRANKUNG_VERLETZUNG") },
        {value:"ZUWEISUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.ZUWEISUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS") },
        {value:"SCHULEMPFEHLUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SCHULEMPFEHLUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS") },
        {value:"GYMNASIUM_MIT_GEWAEHLTEM_BILINGUALEN_BILDUNGSGANG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.GYMNASIUM_MIT_GEWAEHLTEM_BILINGUALEN_BILDUNGSGANG") },
        {value:"SPORTKLASSE_NRW", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SPORTKLASSE_NRW") },
        {value:"AUSZUBILDENDE_VON_BEZIRKSFACHKLASSEN", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.AUSZUBILDENDE_VON_BEZIRKSFACHKLASSEN") },
        {value:"INTERNAT", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.INTERNAT") },
        {value:"ARBEITSLOSIGKEIT_UND_BERUFSSCHULPFLICHTIGKEIT", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.ARBEITSLOSIGKEIT_UND_BERUFSSCHULPFLICHTIGKEIT") },
        {value:"SONSTIGES", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SONSTIGES") },
      ];
      if(this.isFahrkostenSonderfallModuleEnabled) {
        const fieldConfigFahrkostenSonderfallFields = this.fieldConfigService
          .getVisibleFieldsForModuleFromSessionStorage('fahrkostenSonderfall')
          .map( it => it.field );
        this.optionSonderfall = this.optionSonderfall
          .filter(it => !it.value || fieldConfigFahrkostenSonderfallFields.includes(it.value));
      }

      this.optionBefoerderung = [
        {id: 'PKW', name: this.translateService.instant("BEFOERDERUNG.PKW")},
        {id: 'SONSTIGE', name: this.translateService.instant("BEFOERDERUNG.SONST")},
        {id: 'FAHRRAD', name: this.translateService.instant("BEFOERDERUNG.FAHRRAD")},
        {id: 'OEPNV', name: this.translateService.instant("BEFOERDERUNG.OEPNV")},
        //{id: 'SPEZIAL', name: this.translateService.instant("BEFOERDERUNG.SPEZIAL")},
        // Bestandssystem legacy, nicht in Excel
        {id: 'MITFAHRER', name: this.translateService.instant("BEFOERDERUNG.MITFAHRER")},
      ];
      if (this.fieldConfigService.getFieldFromFieldConfig('befoerderungSpezial', this.fieldConfig)?.visible) {
        this.optionBefoerderung = [...this.optionBefoerderung, {id: 'SPEZIAL', name: this.translateService.instant("BEFOERDERUNG.SPEZIAL")}];
      }
    });
    this.landList = Object.keys(Land).map((name) => {
      return { name, value: Land[name as keyof typeof Land],};
    });
  }

  checkForSBBackend() : Promise<string>{
    return new Promise<string>((resolve, reject) =>{
      this.applicationPropertiesService.getApplicationProperties()
        .pipe(catchError((err) => {
          reject(err.toString());
          return EMPTY;
        }))
        .subscribe((data: ApplicationPropertiesDTO) => {
          this.hasSachbearbeiterBackend = data.hasSachbearbeiterBackend;
          resolve('success');
        })
    });
  }

  setBenutzer(){
    if (sessionStorage.getItem('user')) {
      this.benutzer = JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO;
      this.benutzerRoles = this.benutzer.roles;
    }
  }

  loadFieldConfig(){
    this.fieldConfig = this.fieldConfigService.getFieldConfigFromSessionStorage(['fahrkosten']);
    if(!this.fieldConfig || this.fieldConfig.length <1){
      console.log('No fieldConfig for Fahrkosten found: setting default values');
      this.setFieldConfigDefault();
    }
  }

  //for validation
  visibleFields:            string[] = [];  //all fields set to visible via fieldConfig, static: loaded once on init
  //fieldConfigValidators :   string[] = [];  //all fields forced to validate via fieldConfig, static: loaded once on init

  alwaysVisible : string[] = ['schule'];
  alwaysValidateIfVisible:  string[] = ['schule','kosten','entfernung'];  //if visible = override fieldConfig and force validation, static: loaded once on init


  requiredFields: string[] = []; // fieldConfigValidators MINUS NEVER validate PLUS ALWAYS validate

  neverValidate : string[] =                // never validate these fields regardless of config, for example optional checkmarks
    [
      'sonderfallEnabled','bezugAsylbewerberleistungsgesetz',
      'adresszusatz','kontoinhaberAdresszusatz','vormundAdresszusatz','praktikumAdresszusatz',
      'praktikumHinweis', 'isVormundadresseAbweichend',
      'meldedatenabgleichAutomatisch', 'textbausteinId',
      'sonderfall',
      'isKontoinhaberAbweichend', 'isBerufskolleg','sonderfallSchuleAusserhalb',
      'sonderfallSchulungsmoeglichkeit','sonderfallFamilienheimfahrten',
      'mitfahrerBei'
    ];

  effectiveValidators: string[]  = [];  //the validators that become effective if you try EINREICHEN

  // Felder die optional sichtbar sind, wenn eine der folgenden Optionen gewählt wird:

  //vormund
  vormundOnOff = ['vormundName', 'vormundVorname','vormundGeburtsdatum','vormundTelefonnummer','vormundEmail','vormundGeschlecht',];
  vormundAdresseAbweichendOnOff = ['vormundStrasse','vormundOrt','vormundPlz','vormundLand','vormundAdresszusatz'];

  //wegtyp
  schulwegOnOff   = ['unterrichtszeitVon','unterrichtszeitBis'];
  praktikumOnOff  = ['praktikumHinweis','praktikumStrasse','praktikumOrt','praktikumPlz','praktikumLand',
    'praktikumStartdatum','praktikumEnddatum','praktikumstageJeWoche','praktikumsvertrag','praktikumAdresszusatz', 'praktikumStelle'];
  beidesOnOff     = ['schultageJeWoche'];

  //schultyp
  schuleOnOff = ['schuljahr','klasse',];
  berufskollegOnOff = ['schuljahr','bildungsgang','bildungsgangdatum','fachbereich'];

  //befoerderung bei fahrkosten
  mitfahrerOnOff = ['mitfahrerBei'];
  pkwOnOff = ['kennzeichen'];
  oepnvOnOff = [];

  //sonderfälle
  sonderfallOnOff = ['sonderfallNachweis'];
  sonderfallSonstOnOff = ['sonderfallBegruendung'];

  asylOnOff = ['bezugNachweis'];

  // konto
  kontoinhaberOnOff = ['kontoinhaberName','kontoinhaberVorname',
    'kontoinhaberStrasse','kontoinhaberOrt','kontoinhaberPlz',
    'kontoinhaberAdresszusatz','kontoinhaberLand','kontoinhaberGeburtsdatum',];

  setFieldConfigDefault() {
    this.fieldConfig = [
      //schueler
      {field: 'vorname', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'name', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'strasse', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'plz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'ort', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'adresszusatz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'land', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'geburtsdatum', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'email', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'emailSchueler', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'telefonnummer', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'geschlecht', visible: true, required: false, label: null, module: 'fahrkosten'},
      //vormund
      {field: 'vormundVorname', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundName', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'isVormundadresseAbweichend', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundStrasse', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundPlz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundOrt', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundLand', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundAdresszusatz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundGeburtsdatum', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundEmail', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundTelefonnummer', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'vormundGeschlecht', visible: true, required: false, label: null, module: 'fahrkosten'},
      //konto
      {field: 'iban', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'bic', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'isKontoinhaberAbweichend', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberVorname', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberName', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberStrasse', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberOrt', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberPlz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberAdresszusatz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberLand', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kontoinhaberGeburtsdatum', visible: true, required: false, label: null, module: 'fahrkosten'},
      //erstattung
      {field: 'typ', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'wegtyp', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'erstattungszeitraumVon', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'erstattungszeitraumBis', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'krankheitstage', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'fehltage', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'befoerderung', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'mitfahrerBei', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kennzeichen', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'kosten', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'entfernung', visible: true, required: false, label: null, module: 'fahrkosten'},
      //schule
      {field: 'schule', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'schuljahr', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'klasse', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'bildungsgang', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'bildungsgangdatum', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'fachbereich', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'schultageJeWoche', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'unterrichtszeitVon', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'unterrichtszeitBis', visible: true, required: false, label: null, module: 'fahrkosten'},
      //praktikum
      {field: 'praktikumHinweis', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumStelle', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumStrasse', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumOrt', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumPlz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumLand', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumAdresszusatz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumStartdatum', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumEnddatum', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumstageJeWoche', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'praktikumsvertrag', visible: true, required: false, label: null, module: 'fahrkosten'},
      // sonderfall
      {field: 'sonderfallEnabled', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'sonderfall', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'sonderfallBegruendung', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'sonderfallSchuleAusserhalb', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'sonderfallSchulungsmoeglichkeit', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'sonderfallFamilienheimfahrten', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'sonderfallNachweis', visible: true, required: false, label: null, module: 'fahrkosten'},
      // bezug
      {field: 'bezugAsylbewerberleistungsgesetz', visible: true, required: false, label: null, module: 'fahrkosten'},
      {field: 'bezugNachweis', visible: true, required: false, label: null, module: 'fahrkosten'},
      // datenschutz
      {field: 'acceptTerms', visible: true, required: false, label: null, module: 'fahrkosten'},
      // fahrkostenKosten
      {field: 'fahrkostenKostenUniversal', visible: true, required: false, label: null, module: 'fahrkosten'},
      // neu
      {field: 'fahrkostenNachweis', visible: true, required: false, label: null, module: 'fahrkosten'},

    ];
  }

  fahrkostenFormFields  = [
    // Felder die ohne Umwege direkt von antragTicketDTO in antragTicketForm geladen werden können.
    'vorname','name','strasse','plz','ort','adresszusatz','land','geburtsdatum',
    'email','emailSchueler','telefonnummer','geschlecht',

    //vormund
    'vormundName', 'vormundVorname','isVormundadresseAbweichend',
    'vormundStrasse','vormundOrt','vormundPlz', 'vormundAdresszusatz',
    'vormundLand', 'vormundGeburtsdatum', 'vormundTelefonnummer', 'vormundEmail','vormundGeschlecht',

    //konto
    'iban','bic','isKontoinhaberAbweichend','kontoinhaberName','kontoinhaberVorname',
    'kontoinhaberStrasse','kontoinhaberOrt','kontoinhaberPlz',
    'kontoinhaberAdresszusatz','kontoinhaberLand','kontoinhaberGeburtsdatum',

    //erstattung
    'typ', 'wegtyp', 'erstattungszeitraumVon','erstattungszeitraumBis','befoerderung',
    'fehltage','krankheitstage', 'mitfahrerBei','kennzeichen','kosten','entfernung',

    //schule
    'schule','isBerufskolleg','schuljahr','klasse','bildungsgang','bildungsgangdatum','fachbereich', 'schultageJeWoche',
    'unterrichtszeitVon','unterrichtszeitBis',

    //praktikum
    'praktikumHinweis','praktikumStelle', 'praktikumStrasse','praktikumPlz','praktikumOrt','praktikumLand','praktikumAdresszusatz',
    'praktikumStartdatum','praktikumEnddatum','praktikumstageJeWoche','praktikumsvertrag','praktikumsvertragId',

    //sonderfall
    'sonderfallEnabled','sonderfall','sonderfallBegruendung',
    'sonderfallSchuleAusserhalb','sonderfallSchulungsmoeglichkeit','sonderfallFamilienheimfahrten',
    'sonderfallNachweis','sonderfallNachweisId',

    //bezug
    'bezugAsylbewerberleistungsgesetz','bezugNachweis','bezugNachweisId',

    //datenschutz
    'acceptTerms',

    //fahrkosten-kosten
    'fahrkostenKostenUniversalList',

    //neu
    'fahrkostenNachweis','fahrkostenNachweisId'

  ];


}
