<div class="card bg-secondary-light">
  <div class="card-header text-center btn-secondary"
       [id] ="fkkUniversal? 'cardHead'+kostenId:'cardHead'+'X'">
    <fa-icon *ngIf="!(!fkkUniversal)&&!readonly"
             type="btn btn-secondary-icon" [icon]="faTrash"
             class="fa-xl custom-card-btn-delete"
             title = "{{ 'FAHRKOSTEN-DETAIL.NACHWEIS-LOESCHEN' | translate }}"
             [id]="fkkUniversal? 'delBtn'+kostenId:'delBtn'+'X'"
             (click)="deleteFkkUniversal()"
    ></fa-icon>
  </div>

  <form [formGroup]="fahrkostenKostenUniversalForm"
        class="card-body"
        [id] ="fkkUniversal? 'cardBody'+kostenId:'cardBody'+'X'">

    <!--universale Felder-->
    <div id="befoerderungAuswahl" class="row row-cols-1 form-group  mt-0 mb-0">
      <div class="col mt-0 mb-0">
        <app-custom-select id="befoerderung"
                           placeholder="-"
                           [customFormGroup]="fahrkostenKostenUniversalForm"
                           [items]="optionBefoerderung"
                           bindLabel="name"
                           bindValue="id"
                           [multiple] = false
                           [clearable] = false
                           [label]="getFieldLabelOrTranslate('befoerderung','FAHRKOSTEN-DETAIL.BEFOERDERUNG')"
                           [readonly] = "readonly"
                           data-cy="kosten_befoerderung">
        </app-custom-select>
      </div>
    </div>
    <div id="datumAuswahl" class="row row-cols-2 form-group mb-0">
      <div class="col mt-0 mb-0">
        <app-custom-select id="monat"
                           placeholder="-"
                           [customFormGroup]="fahrkostenKostenUniversalForm"
                           [items]="monateList"
                           bindLabel="monatName"
                           bindValue="monatName"
                           [multiple] = false
                           [clearable] = false
                           [label]="getFieldLabelOrTranslate('monat','STANDARD.MONAT')"
                           [readonly] = "readonly"
                           (onchange) = "updateDatepickerStartDate()"
                           data-cy="kosten_monat"
        ></app-custom-select>
      </div>
      <div class="col mt-0 mb-0">
        <app-custom-select id="jahr"
                           placeholder="-"
                           [customFormGroup]="fahrkostenKostenUniversalForm"
                           [items]="jahreList"
                           bindLabel="jahr"
                           bindValue="jahr"
                           [multiple] = false
                           [clearable] = false
                           [label]="getFieldLabelOrTranslate('jahr','STANDARD.JAHR')"
                           [readonly] = "readonly"
                           (onchange) = "updateDatepickerStartDate()"
                           data-cy="kosten_jahr"
        ></app-custom-select>
      </div>
    </div>
    <ng-template #selectedDate let-date>
      <span
        [style] = "isInGefahreneTage(date,'gefahreneTage')? 'background-color:lightpink;border-radius:50%;padding:2px;text-align:right;':'background-color:white;border-radius:50%;padding:2px;text-align:right;'">
        {{date.day}}
      </span>
    </ng-template>
    <div  id="gefahreneTageAuswahl"
          class="row row-cols-1 form-group mt-0 mb-3">
      <div class="col col-lg">
        <label for="gefahreneTageInputGroup">
          {{getFieldLabelOrTranslate('gefahreneTage','FAHRKOSTEN-DETAIL.GEFAHREN')}}
        </label>
        <br>
        <div class="input-group" id="gefahreneTageInputGroup" (click)="(!readonly)? dgefahreneTage.toggle():null">
          <div class="input-group-prepend" >
            <span class="input-group-text">
              <fa-icon [icon]="faCalendar" type="button"
                       style="margin-left:-3px;margin-right:-3px;"
                       class="fa-xl custom-card-btn-calendar datepicker"
                       title = "{{ 'FAHRKOSTEN-DETAIL.GEFAHREN' | translate }}"
                       [id]="fkkUniversal? 'datepickerBtn'+fkkUniversal.kostenId:'datepickerBtn'+'Y'"
              ></fa-icon>
            </span>
          </div>
          <input id="gefahreneTage" name="gefahreneTage" type="text"
                 readonly = "readonly"
                 class="gefahreneTage form-control"
                 formControlName="gefahreneTage"
                 data-cy="kosten_gefahreneTage"
          >
          <!--dummy input field to which datepicker is anchored
          typical ways to hide it didn't work without disabling datepicker
          and datepicker cannot be attached to existing input fields,
          because it overwrites their content-->
          <input ngbDatepicker #dgefahreneTage="ngbDatepicker"
                 style="width:0;height:0;border:0;padding:0;margin:0;"
                 autoclose = false
                 (dateSelect)="selectionDate($event)"
                 [dayTemplate]="selectedDate"
                 navigation = "none"
                 outsideDays = "hidden"
                 [startDate] = "datepickerStartDate"
                 [readonly] = "readonly"
                 id="datum"
                 formControlName="datum"
                 data-cy="kosten_gefahreneTage_dates"
          >
        </div>
      </div>
    </div>
    <!--ende: universale Felder-->

    <!--felder aus fahrkostenKosten-->
    <div  *ngIf="effectivelyVisible.includes('belegArt')"
          id="belegAuswahl"
          class="row row-cols-1 form-group  mt-0 mb-0">
      <div class="col mt-0 mb-0">
        <app-custom-select id="belegArt"
                           placeholder="-"
                           [customFormGroup]="fahrkostenKostenUniversalForm"
                           [items]="belegartList"
                           bindLabel="name"
                           bindValue="name"
                           [multiple] = false
                           [clearable] = false
                           [label]="getFieldLabelOrTranslate('belegArt','FAHRKOSTEN-DETAIL.BELEGART')"
                           [readonly] = "readonly"
                           data-cy="kosten_belegArt"
        ></app-custom-select>
      </div>
    </div>
    <div  *ngIf="effectivelyVisible.includes('belegAnzahl')"
          id="anzahlPreise"
          class="row justify-content-between" >
      <div class="col mt-0 mb-0">
        <app-custom-input id="belegAnzahl"
                          [type] = "'number'"
                          [min]="0"
                          [max]="365"
                          [customFormGroup] = "fahrkostenKostenUniversalForm"
                          [label]="getFieldLabelOrTranslate('belegAnzahl','FAHRKOSTEN-DETAIL.ANZAHL')"
                          [readonly] = "readonly"
                          (click) = recalcGesamtpreis()
                          data-cy="kosten_belegAnzahl"
        ></app-custom-input>
      </div>
      <div  *ngIf="effectivelyVisible.includes('einzelpreis')"
            class="col" >
        <app-custom-input
          id="einzelpreis"
          [customFormGroup]="fahrkostenKostenUniversalForm"
          [label]="getFieldLabelOrTranslate('einzelpreis','FAHRKOSTEN-DETAIL.EINZELPREIS')"
          [readonly] = "readonly"
          [placeholder]="'0,00'"
          [extra_field_text]="'STANDARD.Currency' | translate"
          (click) = recalcGesamtpreis()
          (onchange) = recalcGesamtpreis()
          data-cy="kosten_einzelpreis"
        ></app-custom-input>
      </div>
    </div>
    <!--ende: felder aus fahrkostenKosten-->

    <!--felder aus fahrkostenFahrzeug-->
    <div id="fahrzeugAuswahl" class="row row-cols-2 form-group mt-0 mb-0">
      <div  *ngIf="effectivelyVisible.includes('fahrzeugtyp')"
            class="col">
        <app-custom-select id="fahrzeugtyp"
                           class="flex-grow-1"
                           placeholder="-"
                           [customFormGroup]="fahrkostenKostenUniversalForm"
                           [items]="fahrzeugtypList"
                           bindLabel="name"
                           bindValue="name"
                           [multiple] = false
                           [clearable] = false
                           [label]="getFieldLabelOrTranslate('fahrzeugtyp','FAHRKOSTEN-DETAIL.FAHRZEUGTYP')"
                           [readonly] = "readonly"
                           data-cy="kosten_fahrzeugtyp">
        </app-custom-select>
      </div>
      <div *ngIf="effectivelyVisible.includes('kennzeichen')"
           class="col">
        <app-custom-input id="kennzeichen"
                          [customFormGroup] = "fahrkostenKostenUniversalForm"
                          [label]="getFieldLabelOrTranslate('kennzeichen','FAHRKOSTEN-DETAIL.KENNZEICHEN')"
                          [readonly] = "readonly"
                          data-cy="kosten_kennzeichen"
        ></app-custom-input>
      </div>
    </div>
    <div *ngIf="effectivelyVisible.includes('gruende')"
         id="gruendeInput" class="row row-cols-1 form-group mt-0 mb-0">
      <app-textarea
        class="flex-grow-1"
        id = "gruende"
        [customFormGroup] = "fahrkostenKostenUniversalForm"
        [label]="getFieldLabelOrTranslate('gruende','FAHRKOSTEN-DETAIL.GRUENDE')"
        [readonly]="readonly"
        noteText="{{'FAHRKOSTEN-DETAIL.GRUENDE' | translate }}"
        [rows] = "3"
        data-cy="kosten_gruende">
      </app-textarea>
    </div>
    <div *ngIf="effectivelyVisible.includes('mitfahrerBei')"
         id="mitfahrerBeiInput" class="row row-cols-1 form-group mt-0 mb-0">
      <app-custom-input id="mitfahrerBei"
                        [customFormGroup] = "fahrkostenKostenUniversalForm"
                        [label]="getFieldLabelOrTranslate('mitfahrerBei','FAHRKOSTEN-DETAIL.MITFAHRER')"
                        [readonly] = "readonly"
                        data-cy="kosten_mitfahrerBei"
      ></app-custom-input>
    </div>
    <div *ngIf="effectivelyVisible.includes('mitgenommen')"
         id="mitgenommenInput" class="row row-cols-1 form-group mt-0 mb-0">
      <app-custom-input
        id="mitgenommen"
        [customFormGroup] = "fahrkostenKostenUniversalForm"
        [label]="getFieldLabelOrTranslate('mitgenommen','FAHRKOSTEN-DETAIL.MITFAHRENDE')"
        [readonly] = "readonly"
        data-cy="kosten_mitgenommen"
      ></app-custom-input>
    </div>
    <!--ende: felder aus fahrkostenFahrzeug-->

    <!--neu: mitfahrer aufschlüsselung-->
    <div *ngIf="effectivelyVisible.includes('hasSchuelermitnahme')"
         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
      <label class="checkbox-container"  style="margin-bottom:2ch;">
        {{getFieldLabelOrTranslate('hasSchuelermitnahme','FAHRKOSTEN-DETAIL.SCHUELERMITNAHME')}}
        <input type="checkbox" class="control control-checkbox custom-control-input"
               formControlName="hasSchuelermitnahme"
               id="hasSchuelermitnahme"
               name="hasSchuelermitnahme"
        >
        <span class="checkmark" data-cy="kosten_hasSchuelermitnahme"></span>
      </label>
      <div id="mitfahrer1"
           *ngIf="effectivelyVisible.includes('mitfahrer1')"
           class="row row-cols-3 form-group mb-0">
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer1Name"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [label] = "'STANDARD.NAME' | translate"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer1Name"
          ></app-custom-input>
        </div>
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer1Vorname"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [label] = "'STANDARD.VORNAME' | translate"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer1Vorname"
          ></app-custom-input>
        </div>
        <div class="col col-lg-3 mt-0 mb-0">
          <app-custom-input
            id="mitfahrer1Anzahl"
            [type] = "'number'"
            [min]="0"
            [max]="28"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [label] = "'FAHRKOSTEN-DETAIL.ANZAHL' | translate"
            [title] = "'FAHRKOSTEN-DETAIL.ANZAHL-MITNAHMEN' | translate"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer1Anzahl"
          ></app-custom-input>
        </div>
      </div>
      <div id="mitfahrer2"
           *ngIf="effectivelyVisible.includes('mitfahrer2')"
           class="row row-cols-3 form-group mb-0" style="margin-top:-20px;">
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer2Name"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer2Name"
          ></app-custom-input>
        </div>
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer2Vorname"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer2Vorname"
          ></app-custom-input>
        </div>
        <div class="col col-lg-3 mt-0 mb-0">
          <app-custom-input
            id="mitfahrer2Anzahl"
            [type] = "'number'"
            [min]="0"
            [max]="28"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [title] = "'FAHRKOSTEN-DETAIL.ANZAHL-MITNAHMEN' | translate"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer2Anzahl"
          ></app-custom-input>
        </div>
      </div>
      <div id="mitfahrer3"
           *ngIf="effectivelyVisible.includes('mitfahrer3')"
           class="row row-cols-3 form-group mb-0" style="margin-top:-20px;">
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer3Name"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer3Name"
          ></app-custom-input>
        </div>
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer3Vorname"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer3Vorname"
          ></app-custom-input>
        </div>
        <div class="col col-lg-3 mt-0 mb-0">
          <app-custom-input
            id="mitfahrer3Anzahl"
            [type] = "'number'"
            [min]="0"
            [max]="28"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [title] = "'FAHRKOSTEN-DETAIL.ANZAHL-MITNAHMEN' | translate"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer3Anzahl"
          ></app-custom-input>
        </div>
      </div>
      <div id="mitfahrer4"
           *ngIf="effectivelyVisible.includes('mitfahrer4')"
           class="row row-cols-3 form-group mb-0" style="margin-top:-20px;">
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer4Name"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer4Name"
          ></app-custom-input>
        </div>
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer4Vorname"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer4Vorname"
          ></app-custom-input>
        </div>
        <div class="col col-lg-3 mt-0 mb-0">
          <app-custom-input
            id="mitfahrer4Anzahl"
            [type] = "'number'"
            [min]="0"
            [max]="28"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [title] = "'FAHRKOSTEN-DETAIL.ANZAHL-MITNAHMEN' | translate"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer4Anzahl"
          ></app-custom-input>
        </div>
      </div>
      <div id="mitfahrer5"
           *ngIf="effectivelyVisible.includes('mitfahrer5')"
           class="row row-cols-3 form-group mb-0" style="margin-top:-20px;">
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer5Name"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer5Name"
          ></app-custom-input>
        </div>
        <div class="col mt-0 mb-0">
          <app-custom-input
            id="mitfahrer5Vorname"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer5Vorname"
          ></app-custom-input>
        </div>
        <div class="col col-lg-3 mt-0 mb-0">
          <app-custom-input
            id="mitfahrer5Anzahl"
            [type] = "'number'"
            [min]="0"
            [max]="28"
            [customFormGroup] = "fahrkostenKostenUniversalForm"
            [title] = "'FAHRKOSTEN-DETAIL.ANZAHL-MITNAHMEN' | translate"
            [readonly] = "readonly"
            data-cy="kosten_mitfahrer5Anzahl"
          ></app-custom-input>
        </div>
      </div>
    </div>

    <!--belege-->
    <div *ngIf="effectivelyVisible.includes('belegUpload')">
      <hr class="hr-fahrkosten mt-1 mb-3" >

      <div class="row ml-2 cardAdd mb-2 mt-0" *ngIf="!readonly">
        <label [for]="'file-input'+kostenId" style="font-size:14px;cursor:pointer;margin-left:8px;color:black;"
               title="{{'FAHRKOSTEN-DETAIL.DATEIEN.HINZUFUEGEN'|translate}}"
               data-cy="kosten_addBeleg">
          <fa-icon [icon]=faPlus
                   class="fa-xl custom-card-btn-addbeleg"
                   style="margin-right:8px;"
                   id="addBelegIcon"

          ></fa-icon>
          {{'FAHRKOSTEN-DETAIL.DATEIEN.HINZUFUEGEN'|translate}}
        </label>
        <input [id]="'file-input'+kostenId" type="file" style="width:0;height:0;"
               [accept]="allowedNachweisFormats"
               (change)="addBeleg($event)"
               data-cy="kosten_file-input"/>
      </div>

      <div class="row ml-2 cardAdd mb-2 mt-0" *ngIf="readonly">
        <label style="font-size:14px;cursor:pointer;margin-left:8px;color:black;"
               for="belegeSection"
        >
          Belege
        </label>
      </div>
      <div  class="col mb-1" id="belegeSection" *ngFor="let belegId of getBelegeMapKeys()" style="margin-left:8px;">
        <app-fahrkosten-belege
          [beleg]    = "belegeList.get(belegId)"
          [readonly] = readonly
          (deleteThisBeleg) = "deleteBeleg($event)"
          data-cy="kosten_belege"
        ></app-fahrkosten-belege>
      </div>
    </div>
    <!--end: belege-->
  </form>
</div>

