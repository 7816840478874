/**
 * @deprecated This component is deprecated. Use ticket-detail-sb-true.
 */

import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FieldConfigService} from 'src/app/@core/services/field-config/field-config.service';
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {AntragTicketDTO} from "../../../models/antrag-ticket/AntragTicketDTO";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TicketStatus} from "../../../models/antrag-ticket/TicketStatus";
import {Wegtyp} from "../../../models/antrag-ticket/Wegtyp";
import {AntragTicketCreateDTO} from "../../../models/antrag-ticket/AntragTicketCreateDTO";
import {AntragTicketSepaCreateDTO} from "../../../models/antrag-ticket/AntragTicketSepaCreateDTO";
import {catchError, pairwise, startWith} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {AntragTicketUpdateDTO} from "../../../models/antrag-ticket/AntragTicketUpdateDTO";
import {AntragTicketSepaUpdateDTO} from "../../../models/antrag-ticket/AntragTicketSepaUpdateDTO";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {AntragTicketService} from "../../../@core/services/antrag-ticket/antrag-ticket.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SchuelerDTO} from "../../../models/schueler/SchuelerDTO";
import {Typ} from "../../../models/antrag-ticket/Typ";
import {SchuelerService} from "../../../@core/services/schueler/schueler.service";
import {faInfoCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {PictureFileComponent} from "../../picture-file/picture-file/picture-file.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Land} from "../../../models/land/Land";
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {TicketDetailParentComponent} from "../ticket-detail-parent/ticket-detail-parent.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ConfirmComponent} from "../../../shared/confirm/confirm.component";
import {ValidatorService} from 'angular-iban';
import {SchuleService} from "../../../@core/services/schule/schule.service";
import {ModuleConfigService} from "../../../@core/services/module-config/module-config.service";
import {PlzValidationHelperService} from "../../../@core/utils/forms/plz-validation-helper.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ImageHelperService} from "../../../@core/utils/forms/image-helper.service";
import {createVormundAgeValidator, DateTimeHelperService} from "../../../@core/utils/forms/datetime-helper.service";
import {toTitlecase} from "../../../@core/utils/forms/string-helper";
import {SchuleDTO} from "../../../models/schule/SchuleDTO";
import {getObjIfExistsInListByAttribute} from "../../../@core/utils/forms/object-list-helper";
import {SessionService} from "../../../@core/services/session/session.service";
import {TranslateService} from "@ngx-translate/core";
import {KeyString} from "../../../models/textbaustein/KeyString";
import {ValidationHelperService} from "../../../@core/utils/forms/validation-helper.service";
import {PFLICHTFELDMARKER} from "../../../../assets/constants/constants";

@Component({
  selector: 'app-ticket-detail-sb-false',
  templateUrl: './ticket-detail-sb-false.component.html',
  styleUrls: ['./ticket-detail-sb-false.component.scss']
})
export class TicketDetailSbFalseComponent implements OnInit, OnDestroy {

  faInfo = faInfoCircle;
  faDelete = faTimesCircle;

  @Input() antragTicketId: string | null;
  @Input() benutzer: BenutzerDTO;
  @Input() benutzerRoles: string[];
  @Input() fieldConfig;
  @Input() optionTyp = [];
  @Input() optionErmaessigung = [];
  @Input() optionGeschlecht = [];
  @Input() optionWegtyp = [];
  @Input() optionSonderfall = [];
  @Input() landList = [];
  @Input() requiredFields: string[] = [];
  @Input() visibleFields: string[] = [];
  @Input() antragTicketFormFields = [];
  @Input() antragTicketSepaFormFields = [];
  @Input() antragTicketGeschwister1FormFields = [];
  @Input() antragTicketGeschwister2FormFields = [];
  @Input() antragTicketGeschwister3FormFields = [];
  @Input() antragTicketGeschwister4FormFields = [];
  @Input() antragTicketGeschwister5FormFields = [];
  @Input() schulwegOnOff = [];
  @Input() praktikumOnOff = [];
  @Input() beidesOnOff = [];
  @Input() schuleOnOff = [];
  @Input() berufskollegOnOff = [];
  @Input() schuljahrOnOff = [];
  @Input() vormundOnOff = [];
  @Input() vormundAdresseAbweichendOnOff;
  @Input() sonderfallOnOff = [];
  @Input() sonderfallSonstOnOff = [];
  @Input() asylOnOff = [];
  @Input() sepaMandatgeberNotAntragst = [];
  @Input() sepaMandatgeberYesAntragst = [];

  pflichtFeldMarker=PFLICHTFELDMARKER;

  draftStatus = TicketStatus.ENTWURF;
  eingereichtStatus = TicketStatus.EINGEREICHT;
  wegtypBEIDES = Wegtyp.BEIDES;
  wegtypPRAKTIKUMSWEG = Wegtyp.PRAKTIKUMSWEG;

  readonly : boolean = false;
  schuelerDeleted : boolean = false;

  isUnderAge: boolean = false;

  today  : string;
  minDate : string;
  maxDate : string;
  minDatePraktikumEnddatum : string;
  maxDateVormundGeburtsdatum : string;
  minDateBirthday : string;
  minDateTicketGueltigAb;
  maxDateTicketGueltigAb;
  gueltigAbInfoText: string;

  schuleList = [];
  schuelerList = [];
  antragstellerList = [];

  // picturefiles
  praktikumsvertrag: string;
  praktikumsvertragIsPDF: boolean = false;
  sonderfallNachweis: string;
  sonderfallNachweisIsPDF: boolean = false;
  bezugNachweis: string;
  bezugNachweisIsPDF: boolean = false;

  antragTicket: AntragTicketDTO;
  antragTicketForm: UntypedFormGroup;

  //buttons
  isDeleteDraftButtonVisible: boolean = false;
  isUpdateEntwurfButtonVisible: boolean = false;
  isEinreichenButtonVisible: boolean = false;

  //subscriptions
  formItemSubscriptions: Subscription[] = [];

  allVormundFieldsInvisible: boolean = false;

  //validators
  effectiveValidators: string[] = [];
  effectivelyVisible: string[] = [];
  isInitializing: boolean = true;
  isSubmitted: boolean = false;
  isMonthSelection: boolean = false;

  //disable checkboxes if readonly == true
  //isBerufskolleg is always disabled in SBfalse, so it's not in this list
  disableCheckboxes: string[] = ['isVormundadresseAbweichend',
    'bezugAsylbewerberleistungsgesetz','sonderfallEnabled','einverstaendnisErklaerung',
    'isAntragstellerNotMandatsgeber','datenschutz','marketingfreigabe'
  ];

  allowedNachweisFormats : string = ImageHelperService.ALLOWED_NACHWEIS_FILE_FORMATS;

  postkorbReference: string;

  existsSessionStorageTextbausteine: boolean = false;

  constructor(
    public fieldConfigService: FieldConfigService,
    public moduleConfigService: ModuleConfigService,
    private customToastService: CustomToastService,
    private antragTicketService: AntragTicketService,
    private router: Router,
    private schuelerService: SchuelerService,
    private schuleService: SchuleService,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public textbausteinService: TextbausteinService,
    public route: ActivatedRoute,
    private parent: TicketDetailParentComponent,
    private sanitizer: DomSanitizer,
    private plzHelper: PlzValidationHelperService,
    private spinner: NgxSpinnerService,
    private imageHelper: ImageHelperService,
    private datetimeHelper: DateTimeHelperService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private validationHelper: ValidationHelperService
  ) { }


  ngOnInit(): void {
    this.spinner.show().catch(()=>{});

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.effectivelyVisible  = [...this.visibleFields];
    this.effectiveValidators = [...this.requiredFields];

    this.makeInit().then(()=>{
      this.isInitializing=false;
      this.setVormundVisibility();
      this.sonderfallTypChange();
      this.isBerufskollegChanged();
      this.adjustSubtitle();
    }).catch(()=>{});
  }

  ngAfterViewInit(){
    this.spinner.hide().catch(()=>{});
  }

  makeInit():Promise<boolean>{
    return new Promise<boolean>((resolve)=>{
      this.isInitializing = true;
      this.antragTicketId = this.route.snapshot.paramMap.get('id');

      this.initListsForSBfalse();
      this.initEmptyForm();
      this.antragstellerList = [...this.schuelerList,{id: -1, fullName: 'Mich selbst'}];

      this.createSubscriptions();
      this.setDateMinMax();
      this.initAboDatumGueltigAb();

      if(this.antragTicketId){
        this.antragTicketService.getAntrag(this.antragTicketId)
          .pipe(
            catchError(() => {
              this.customToastService.showError('Der Antrag konnte nicht geladen werden.');
              return EMPTY;
            })
          )
          .subscribe((data: AntragTicketDTO) => {
            this.antragTicket = {...data};
            this.postkorbReference = this.antragTicket.postkorbReference;
            this.autoPatchForm(this.antragTicket,this.antragTicketFormFields);
            // "manual" trigger for valueChanges subscriptions for form elements
            // where null is a legit input value, e.g. Sonderfall option "trifft nicht zu"
            // valueChanges only reacts if new value does not equal previous value
            // it's set up like that to prevent unnecessary updates
            // however, initvalue is null, and if loaded legit value is null too, no changeDetection is triggered
            this.sonderfallTypChange();
            this.isBerufskollegChanged();
            if(this.antragTicket.antragTicketSepa)
              this.autoPatchForm(this.antragTicket.antragTicketSepa,this.antragTicketSepaFormFields);
            this.antragTicketForm.patchValue({ isAntragstellerNotMandatsgeber: !this.antragTicket.antragTicketSepa.isAntragstellerMandatsgeber } );
            if(this.isMonthSelection){
              //remove first day from Month from  ('2022-08-01') to ('2022-08')
              this.antragTicketForm.patchValue({aboGueltigkeit: this.antragTicket.aboGueltigkeit?.toString().slice(0,7)});
            }
            if(this.antragTicket.geschwisterkind1) {
              this.autoPatchForm(this.antragTicket.geschwisterkind1,this.antragTicketGeschwister1FormFields);
              this.setGeschwisterkind(1);
            }
            if(this.antragTicket.geschwisterkind2) {
              this.autoPatchForm(this.antragTicket.geschwisterkind2,this.antragTicketGeschwister2FormFields);
              this.setGeschwisterkind(2);
            }
            if(this.antragTicket.geschwisterkind3) {
              this.autoPatchForm(this.antragTicket.geschwisterkind3,this.antragTicketGeschwister3FormFields);
              this.setGeschwisterkind(3);
            }
            if(this.antragTicket.geschwisterkind4) {
              this.autoPatchForm(this.antragTicket.geschwisterkind4,this.antragTicketGeschwister4FormFields);
              this.setGeschwisterkind(4);
            }
            if(this.antragTicket.geschwisterkind5) {
              this.autoPatchForm(this.antragTicket.geschwisterkind5,this.antragTicketGeschwister5FormFields);
              this.setGeschwisterkind(5);
            }
            if(this.antragTicket.schule){
              this.antragTicketForm.patchValue({schule: this.antragTicket.schule});
              this.schuleChange();
            }
            if(this.antragTicket.praktikumsvertragId)
              this.antragTicketService.downloadPraktikumsvertrag(this.antragTicketId)
                .pipe(catchError(() => {
                  this.customToastService.showError("Praktikumsvertrag konnte nicht geladen werden.");
                  return EMPTY;
                }))
                .subscribe(result => {
                  this.praktikumsvertragIsPDF = result.image.indexOf("application/pdf")>-1;
                  this.praktikumsvertrag = result.image;
                });
            if(this.antragTicket.sonderfallNachweisId)
              this.antragTicketService.downloadSonderfallNachweis(this.antragTicketId)
                .pipe(catchError(() => {
                  this.customToastService.showError("Sonderfallnachweis konnte nicht geladen werden.");
                  return EMPTY;
                }))
                .subscribe(result => {
                  this.sonderfallNachweisIsPDF = result.image.indexOf("application/pdf")>-1;
                  this.sonderfallNachweis = result.image;
                });
            if(this.antragTicket.bezugNachweisId)
              this.antragTicketService.downloadBezugNachweis(this.antragTicketId)
                .pipe(catchError(() => {
                  this.customToastService.showError("Bezugnachweis konnte nicht geladen werden.");
                  return EMPTY;
                }))
                .subscribe(result => {
                  this.bezugNachweisIsPDF = result.image.indexOf("application/pdf")>-1;
                  this.bezugNachweis = result.image;
                });
            this.readonly = this.antragTicket.status != TicketStatus.ENTWURF ;
            this.schuelerDeleted = this.antragTicket.schueler?.deleted || false;
            if(this.schuelerDeleted){
              this.customToastService.showWarning(this.readonly? "Schüler:in wurde gelöscht." :
                "Schüler:in wurde gelöscht. Sie müssen eine/n gültige/n Schüler:in auswählen, um den Antrag einreichen zu können.");
            }
            resolve(true);
          });
      } else {
        // "manual" trigger for sonderfallTypChange() is needed due to valueChanges sunscription
        // valueChanges only reacts if new value is not null
        // however, sonderfall-Option "trifft nicht zu" has null-value,
        // which necessitates this explicit trigger.
        // this facilitates the "make invisible" of sonderfallBegruendung and sonderfallNachweis
        // for option "trifft nicht zu"
        this.sonderfallTypChange();
        resolve(true);
      }
    });
  }

  setDateMinMax(){
    this.minDate = DateTimeHelperService.CALENDAR_MINIMUM;
    this.minDateBirthday = DateTimeHelperService.BIRTHDATE_MINIMUM;
    this.today   = this.datetimeHelper.formattedToday();
    this.maxDate = DateTimeHelperService.CALENDAR_MAXIMUM;
    this.maxDateVormundGeburtsdatum = this.datetimeHelper.maxDateVormund();
  }

  updateEverything() {
    this.disableCheckboxesFunc();
    this.setButtonVisibility();
    this.vormundChange();
    this.wegtypChange();
    this.schuleChange();
    this.sonderfallTypChange();
    this.sepaChange();
    this.asylChange();
    this.praktikumDatumChange();
    if(!this.readonly && !this.isInitializing) {
      this.correctLand();
    }
  }

  private setVormundVisibility() {
    const vormundNameVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundName', this.fieldConfig)?.visible;
    const vormundVornameVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundVorname', this.fieldConfig)?.visible;
    const isVormundadresseAbweichendVisible = this.fieldConfigService.getFieldFromFieldConfig('isVormundadresseAbweichend', this.fieldConfig)?.visible;
    const vormundStrasseVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundStrasse', this.fieldConfig)?.visible;
    const vormundOrtVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundOrt', this.fieldConfig)?.visible;
    const vormundPlzVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundPlz', this.fieldConfig)?.visible;
    const vormundAdresszusatzVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundAdresszusatz', this.fieldConfig)?.visible;
    const vormundLandVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundLand', this.fieldConfig)?.visible;
    const vormundGeburtsdatumVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundGeburtsdatum', this.fieldConfig)?.visible;
    const vormundTelefonnummerVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundTelefonnummer', this.fieldConfig)?.visible;
    const vormundEmailVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundEmail', this.fieldConfig)?.visible;
    const vormundGeschlechtVisible = this.fieldConfigService.getFieldFromFieldConfig('vormundGeschlecht', this.fieldConfig)?.visible;

    if (!vormundNameVisible
      && !vormundVornameVisible
      && !isVormundadresseAbweichendVisible
      && !vormundStrasseVisible
      && !vormundOrtVisible
      && !vormundPlzVisible
      && !vormundAdresszusatzVisible
      && !vormundLandVisible
      && !vormundGeburtsdatumVisible
      && !vormundTelefonnummerVisible
      && !vormundEmailVisible
      && !vormundGeschlechtVisible) {
      this.allVormundFieldsInvisible = true;
    }
  }

  createSubscriptions(){
    /*
      SonarLint marks startWith as deprecated, but it's only deprecated if used with a scheduler
      startWith(null) and startWith(undefined) are ok
     */
    if(!this.antragTicketForm)
      return;
    this.formItemSubscriptions.push(
      this.antragTicketForm.get('geburtsdatum').valueChanges
        .subscribe(() => {
          setTimeout(() =>{
            this.vormundChange();
          });
        }),
      this.antragTicketForm.get('wegtyp').valueChanges
        .subscribe(() => {
        setTimeout(() =>{
          this.wegtypChange();
        });
      }),
      this.antragTicketForm.get('schule').valueChanges
        .subscribe(() => {
        setTimeout(() =>{
          this.schuleChange();
        });
      }),
      this.antragTicketForm.get('isBerufskolleg').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.isBerufskollegChanged();
          }
        }),
      this.antragTicketForm.get('isAntragstellerNotMandatsgeber').valueChanges
        .subscribe(() => {
        setTimeout(() =>{
          this.sepaChange();
        });
      }),
      this.antragTicketForm.get('sonderfall').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.sonderfallTypChange();
          }
        }),
      this.antragTicketForm.get('isVormundadresseAbweichend').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.isVormundadresseAbweichendChanged();
          }
        }),
      this.antragTicketForm.get('bezugAsylbewerberleistungsgesetz').valueChanges.subscribe(() => {
        setTimeout(() =>{
          this.asylChange();
        });
      }),
      this.antragTicketForm.get('praktikumStartdatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.praktikumDatumChange();
          }
        }),
      this.antragTicketForm.get('praktikumEnddatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.praktikumDatumChange();
          }
        }),
      this.antragTicketForm.get('einstieghaltestelle').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.checkForHaltestellenMatch('einstieghaltestelle');
          }
        }),
      this.antragTicketForm.get('ausstieghaltestelleHinweg').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.checkForHaltestellenMatch('ausstieghaltestelleHinweg');
          }
        }),
      this.getFormItem('land')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('plz');
      }),
      this.getFormItem('vormundLand')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('vormundPlz');
      }),
      this.getFormItem('praktikumLand')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('praktikumPlz');
      }),
      this.getFormItem('landSepa')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('plzSepa');
      })
    )
  }

  disableCheckboxesFunc(){
    if(this.readonly === true && !(!this.antragTicketForm)){
      this.disableCheckboxes.forEach(cb=>this.antragTicketForm.get(cb)?.disable());
    } else if(this.readonly !== true && !(!this.antragTicketForm)) {
      this.disableCheckboxes.forEach(cb=>this.antragTicketForm.get(cb)?.enable());
    }
  }

  resetFormValidators(varnames: string[]){
    varnames.forEach((varname:string) => {
      if(this.getFormItem(varname)){
        this.getFormItem(varname).clearValidators();
        this.getFormItem(varname).clearAsyncValidators();
        this.getFormItem(varname).setErrors(null);
        this.getFormItem(varname).updateValueAndValidity();
      }
    });
  }

  resetFormValues(varnames: string[]) {
    if( this.readonly || this.isInitializing || !this.antragTicketForm )
      return;
    varnames.forEach((varname: string) => {
      this.antragTicketForm?.get(varname)?.patchValue(null);
    });
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.visibleFields){
      // visible fields / fieldConfig are not loaded fast enough
      this.effectivelyVisible = this.visibleFields.slice();   // empty .slice() = copy-by-value, equivalent to Java's Arrays.copy()
      this.effectiveValidators = this.requiredFields.slice(); // empty .slice() = copy-by-value, equivalent to Java's Arrays.copy()
      this.updateEverything();
    }
    this.updateEffectiveValidators();
  }

  adjustSubtitle(){
    if(this.readonly!==true)
      Promise.resolve(null).then(() => this.parent.subtitle =
        this.textbausteinService.printTextbausteinByKey( KeyString.SUBTITLE_TEXT_TICKET,
          this.translateService.instant('TICKETDETAIL.SUBTITLE_TEXT_TICKET'))
      ).catch(()=>{});
    else
      Promise.resolve(null).then(() => this.parent.subtitle = '').catch(()=>{});
  }

  updateEffectivelyVisible(add: string[], remove: string[]){
    if(this.effectivelyVisible) {
      remove.forEach(varName => {
        if (!add.includes(varName)) {
          while (this.effectivelyVisible.includes(varName)) {
            this.effectivelyVisible.splice(this.effectivelyVisible.indexOf(varName), 1);
            this.resetFormValues([varName]);
          }
        }
      });
      add.forEach(varName => {
        if (!this.effectivelyVisible.includes(varName)) {
          this.effectivelyVisible = [...this.effectivelyVisible, varName];
        }
      });
    }
    this.updateEffectiveValidators();
  }

  updateEffectiveValidators() {
    if (this.effectivelyVisible.length > 0) {
      this.effectiveValidators = [];
      this.requiredFields.forEach(varName => {
        if (this.effectivelyVisible.includes(varName)) {
          this.effectiveValidators = [...this.effectiveValidators, varName];
        }
      });
    }
    if(this.isSubmitted===true)
      this.applyValidators().catch(()=>{});
    else {  // these validators always react on bad input
      this.addFormalValidators();
    }
  }

  initListsForSBfalse(){
    this.schuleService.getSchulen(true)
      .pipe(catchError(() => {
        this.loadErrorMessage();
        return EMPTY;
      }))
      .subscribe(schulen => {
        this.schuleList = schulen;
        if (!schulen || schulen.length < 1) {
          this.loadErrorMessage();
        }
      });

    this.schuelerService.getSchuelerList()
      .pipe(catchError(() => {
        this.loadErrorMessage();
        return EMPTY;
      }))
      .subscribe(schueler => {
        schueler.forEach((gizmo) => {
          this.schuelerList = [...this.schuelerList, {id: gizmo.id, fullName: ""+gizmo.vorname+' '+gizmo.name}];
          this.antragstellerList = [...this.antragstellerList, {id: gizmo.id, fullName: ""+gizmo.vorname+' '+gizmo.name}];
        });
      });
  }

  loadErrorMessage(){
    this.customToastService.showError('Daten konnten nicht geladen werden. Stellen Sie sicher, dass Ihre Sitzung im Portal nicht abgelaufen ist.');
    this.router.navigate(['pages/ticket-table']).catch(()=>{});
  }

  wegtypChange(){
    const status = this.getFormItem('wegtyp')?.value;
    if(!status) return;
    let add, remove;
    switch(status){
      case(Wegtyp.SCHULWEG): {
        add = this.schulwegOnOff;
        remove = this.beidesOnOff.concat(this.praktikumOnOff);
        break;
      }
      case(Wegtyp.BEIDES): {
        add = this.schulwegOnOff.concat(this.praktikumOnOff).concat(this.beidesOnOff);
        remove = [];
        break;
      }
      case(Wegtyp.PRAKTIKUMSWEG): {
        add = this.praktikumOnOff;
        remove = this.schulwegOnOff.concat(this.beidesOnOff);
      }
    }
    if(remove.includes('praktikumsvertrag'))
      this.deleteNachweis('praktikumsvertrag');
    this.updateEffectivelyVisible(add,remove);
  }

  schuleChange(){
    // reset values that depend on schule
    if( !this.readonly && !this.isInitializing && !!this.antragTicketForm ) {
      this.antragTicketForm.patchValue({
        isBerufskolleg: null,
        ticket: null,
        klasse: null,
        bildungsgang: null,
        fachbereich: null,
        bildungsgangdatum: null,
        schuljahr: null,
        einstieghaltestelle: null,
        ausstieghaltestelleHinweg: null,
        aboGueltigkeit: null
      });
    }

    if(!this.getFormItem('schule')) return;

    const schuleName =  this.getFormItem('schule').value;
    this.schuleList.forEach((schule)=>{
      if(schuleName === schule.name){
        this.antragTicketForm.patchValue({isBerufskolleg: schule?.berufskolleg});
      }
    });
    this.isBerufskollegChanged();
  }

  isBerufskollegChanged(){
    let add, remove;
    let isB = this.getFormItem('isBerufskolleg').value;
    if(!(!isB)&&isB===true){
      add = this.berufskollegOnOff;
      remove = this.schuleOnOff;
    } else if(isB===false){
      add = this.schuleOnOff;
      remove = this.berufskollegOnOff;
    } else {  // undefined or null value
      add = [];
      remove = this.schuleOnOff.concat(this.berufskollegOnOff);
    }
    this.updateEffectivelyVisible(add,remove);
  }

  vormundChange(){
    if (!this.getFormItem('geburtsdatum')) return;
    const underage: boolean = this.datetimeHelper.isYoungerThan(this.getFormItem('geburtsdatum')?.value,18);
    this.antragTicketForm?.patchValue({isUnderAge: underage});
    this.isUnderAge = underage;
    let add, remove;
    if(underage){
      add = this.vormundOnOff;
      remove = ['emailSchueler','telefonnummer'];
    } else {
      add = ['emailSchueler','telefonnummer'];
      remove = this.vormundOnOff;
    }
    this.updateEffectivelyVisible(add,remove);
    this.isVormundadresseAbweichendChanged();
  }

  sonderfallTypChange(){
    let add, remove;
    if (this.getFormItem('sonderfall')?.value) {
      if (this.getFormItem('sonderfall')?.value === 'SONSTIGES'){
        add = this.sonderfallOnOff.concat(this.sonderfallSonstOnOff);
        remove = [];
      } else {
        add = this.sonderfallOnOff;
        remove = this.sonderfallSonstOnOff;
      }
    } else {
      add = [];
      remove = this.sonderfallOnOff.concat(this.sonderfallSonstOnOff);
    }
    if(remove.includes('sonderfallNachweis'))
      this.deleteNachweis('sonderfallNachweis');
    this.updateEffectivelyVisible(add,remove);
  }

  asylChange(){
    let add, remove;
    if(!this.getFormItem('bezugAsylbewerberleistungsgesetz')) return;
    if(this.getFormItem('bezugAsylbewerberleistungsgesetz').value){
      add = this.asylOnOff;
      remove = [];
    } else {
      add = [];
      remove = this.asylOnOff;
    }
    if(remove.includes('bezugNachweis'))
      this.deleteNachweis('bezugNachweis');
    this.updateEffectivelyVisible(add,remove);
  }

  sepaChange(){
    let add, remove;
    if(!this.getFormItem('isAntragstellerNotMandatsgeber')) return;
    if(this.getFormItem('isAntragstellerNotMandatsgeber').value){
      add = this.sepaMandatgeberNotAntragst;
      remove = this.sepaMandatgeberYesAntragst;
      this.getFormItem('iban').removeValidators([ ValidatorService.validateIban]);
      this.getFormItem('ibanSepa').setValidators([ ValidatorService.validateIban]);

      this.getFormItem('iban').updateValueAndValidity();
      this.getFormItem('ibanSepa').updateValueAndValidity();
    } else {
      add = this.sepaMandatgeberYesAntragst;
      remove = this.sepaMandatgeberNotAntragst;
      this.getFormItem('iban').setValidators([ ValidatorService.validateIban]);
      this.getFormItem('ibanSepa').removeValidators([ ValidatorService.validateIban]);

      this.getFormItem('iban').updateValueAndValidity();
      this.getFormItem('ibanSepa').updateValueAndValidity();
    }
    this.updateEffectivelyVisible(add,remove);
  }

  isVormundadresseAbweichendChanged(){
    let add, remove;
    if((this.getFormItem('isVormundadresseAbweichend')?.value) === true){
      add = this.vormundAdresseAbweichendOnOff;
      remove = [];
    } else {
      add = [];
      remove = this.vormundAdresseAbweichendOnOff;
    }
    this.updateEffectivelyVisible(add,remove);
  }

  praktikumDatumChange() {
    if (this.getFormItem('praktikumStartdatum')) {
      this.minDatePraktikumEnddatum = this.getFormItem('praktikumStartdatum')?.value;
      if (!(!this.getFormItem('praktikumEnddatum'))
        && this.getFormItem('praktikumEnddatum')?.value < this.getFormItem('praktikumStartdatum')?.value) {
        this.getFormItem('praktikumEnddatum')?.patchValue(this.getFormItem('praktikumStartdatum')?.value);
      }
    }
  }

  checkForHaltestellenMatch(caller:string){
    if(!this.antragTicketForm.get(caller)?.value)
      return;
    let einstieg = this.antragTicketForm.get('einstieghaltestelle')?.value || undefined;
    let ausstieg = this.antragTicketForm.get('ausstieghaltestelleHinweg')?.value || undefined;
    if( einstieg == ausstieg ){
      this.antragTicketForm.get(caller).patchValue(undefined,{emitEvent: false, onlySelf: true});
      this.customToastService.showError("Einstieg- und Ausstieghaltestelle dürfen nicht identisch sein.")
    }
  }



  setButtonVisibility(){
    if( !this.readonly ){
      this.isEinreichenButtonVisible = !this.schuelerDeleted;
      this.isUpdateEntwurfButtonVisible = !this.schuelerDeleted;
      this.isDeleteDraftButtonVisible = !!this.antragTicketId;
    } else {
      this.isDeleteDraftButtonVisible = false;
      this.isUpdateEntwurfButtonVisible = false;
      this.isEinreichenButtonVisible = false;
    }
  }

  autoPatchForm(source: any, fields: string[]){
    fields.forEach((varname) => {
      if (source[varname]!= null && typeof source[varname] == "object"){ //geschwisterkind
        this.antragTicketForm.patchValue({[varname] : source[varname]?.id});
      }
      else if(source[varname]){
       this.antragTicketForm.patchValue({[varname] : source[varname]});
      }
    });
  }

  /**
   * Auto create DTO from form values
   * @param targetDTO - the DTO to be filled
   * @param fields - the fields to be filled in the DTO
   */
  autoCreateDTO(targetDTO: any, fields: string[]){
    fields.forEach((varname) => {
      targetDTO[varname] = this.getFormItem(varname)?.value;
    });
  }

  addSchueler(){
    this.saveOrUpdate(TicketStatus.ENTWURF,
      "Der Antrag wurde als Entwurf gespeichert. Sie werden zur Schülerseite weitergeleitet.",
      'schueler-table');
  }

  applyValidators(): Promise<any> {
    return new Promise ((resolve) => {
      this.clearAllValidators();
      this.effectiveValidators.forEach((varName)=>{
        this.getFormItem(varName)?.setValidators([Validators.required]);
        if (varName === 'datenschutz' || varName === 'marketingfreigabe') {
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.requiredTrue]);
        }
        else if (varName === 'emailSchueler') {
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.email]);
        }
        else if (varName.toLowerCase().includes('plz')) {
          this.setPLZValidatorForControl(varName);
        }
        else if (varName.toLowerCase().includes('iban')) {
          this.getFormItem(varName)?.setValidators([Validators.required,ValidatorService.validateIban]);
        }
        else if (varName === 'vormundGeburtsdatum') {
          this.getFormItem(varName)?.setValidators([Validators.required,createVormundAgeValidator()])
        }
        else if (varName === 'praktikumsvertrag' && !(!this.praktikumsvertrag)) {
          this.getFormItem(varName)?.clearValidators();
        }
        else if (varName === 'sonderfallNachweis' && !(!this.sonderfallNachweis)) {
          this.getFormItem(varName)?.clearValidators();
        }
        else if (varName === 'bezugNachweis' && !(!this.bezugNachweis)) {
          this.getFormItem(varName)?.clearValidators();
        }
        this.getFormItem(varName)?.updateValueAndValidity();
      });
      resolve('hurra');
    })
  }

  clearAllValidators(){
    const controls = this.antragTicketForm.controls;
    for (const name in controls) {
      controls[name].clearAsyncValidators();
      controls[name].clearValidators();
      if( !( (name === 'iban') || (name === 'ibanSepa') || (name === 'vormundGeburtsdatum') ) )
        controls[name].setErrors(null);
    }
  }

  createAntragTicket(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    this.saveCreate(targetStatus).then((result)=>{
      if(result){
        if(goToPath=='ticket-detail') goToPath += '/'+this.antragTicket.id;
        this.router.navigate(['pages/'+goToPath]).then(() => {
          this.customToastService.showSuccess(saveMsg);
        }).catch(()=>{});
      } else {
        this.spinner.hide().catch(()=>{});
      }
    }).catch(()=>{
      this.spinner.hide().catch(()=>{});
    });
  }

  saveCreate(targetStatus) : Promise<boolean> {
    return new Promise ((resolve)=>{
      let antragTicketCreateDTOtemp : AntragTicketCreateDTO = {} as AntragTicketCreateDTO;
      this.autoCreateDTO(antragTicketCreateDTOtemp,this.antragTicketFormFields);
      antragTicketCreateDTOtemp.geschwisterkind1Id = this.getFormItem('geschwisterkind1')?.value?.length !== 0 ? this.getFormItem('geschwisterkind1').value : null;
      antragTicketCreateDTOtemp.geschwisterkind2Id = this.getFormItem('geschwisterkind2')?.value?.length !== 0 ? this.getFormItem('geschwisterkind2').value : null;
      antragTicketCreateDTOtemp.geschwisterkind3Id = this.getFormItem('geschwisterkind3')?.value?.length !== 0 ? this.getFormItem('geschwisterkind3').value : null;
      antragTicketCreateDTOtemp.geschwisterkind4Id = this.getFormItem('geschwisterkind4')?.value?.length !== 0 ? this.getFormItem('geschwisterkind4').value : null;
      antragTicketCreateDTOtemp.geschwisterkind5Id = this.getFormItem('geschwisterkind5')?.value?.length !== 0 ? this.getFormItem('geschwisterkind5').value : null;
      //add first day to Month from ('2022-08') to ('2022-08-01')
      if(this.isMonthSelection){
        antragTicketCreateDTOtemp.aboGueltigkeit = this.getFormItem('aboGueltigkeit')?.value?.length !== 0 ? new Date(this.getFormItem('aboGueltigkeit').value.toString()+'-01') : null;
      }

      let antragTicketSepaCreateDTOtemp : AntragTicketSepaCreateDTO = {} as AntragTicketSepaCreateDTO;
      this.autoCreateDTO(antragTicketSepaCreateDTOtemp, this.antragTicketSepaFormFields);
      antragTicketCreateDTOtemp.status = targetStatus;
      if(this.getFormItem('schuelerId').value!==-1){ // -1 == mich selbst
        antragTicketCreateDTOtemp.schuelerId = this.getFormItem('schuelerId').value;
      } else {
        antragTicketCreateDTOtemp.schuelerId = undefined;
      }
      antragTicketSepaCreateDTOtemp.isAntragstellerMandatsgeber = this.getFormItem('isAntragstellerNotMandatsgeber')?.value?.length !== 0 ? !this.getFormItem('isAntragstellerNotMandatsgeber').value : null;
      antragTicketCreateDTOtemp.antragTicketSepa = antragTicketSepaCreateDTOtemp;

      let praktikumsvertrag: File = undefined;
      if (!(!this.getFormItem('praktikumsvertrag')) && !(!this.getFormItem('praktikumsvertragFile')?.value)) {
        praktikumsvertrag = this.getFormItem('praktikumsvertragFile').value;
      }
      let sonderfallNachweis: File = undefined;
      if (!(!this.getFormItem('sonderfallNachweis')) && !(!this.getFormItem('sonderfallNachweisFile')?.value)) {
        sonderfallNachweis = this.getFormItem('sonderfallNachweisFile').value;
      }
      let bezugNachweis: File = undefined;
      if (!(!this.getFormItem('bezugNachweis')) && !(!this.getFormItem('bezugNachweisFile')?.value)) {
        bezugNachweis = this.getFormItem('bezugNachweisFile').value;
      }

      this.antragTicketService.createAntrag(antragTicketCreateDTOtemp, praktikumsvertrag, sonderfallNachweis, bezugNachweis)
        .pipe(
          catchError((err) => {
            if (err.error.message == 'required_fields_not_present') {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden. Bitte füllen Sie alle Pflichtfelder aus.');
            } else {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden.');
            }
            resolve(false);
            return EMPTY;
          })
        )
        .subscribe((data: AntragTicketDTO) => {
          this.antragTicket = {...data};
          this.antragTicketId = ''+this.antragTicket.id;
          resolve(true);
        });
    });
  }

  updateAntragTicket(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    this.saveUpdate(targetStatus).then(()=>{
      if(goToPath=='ticket-detail') goToPath += '/'+this.antragTicket.id;
      this.router.navigate(['pages/'+goToPath]).then(() => {
        this.customToastService.showSuccess(saveMsg);
      }).catch(()=>{});
    }).catch(()=>{
      this.spinner.hide().catch(()=>{});
    });
  }

  saveUpdate(targetStatus): Promise<boolean> {
    return new Promise<boolean> ((resolve)=>{
      let antragTicketUpdateDTOtemp : AntragTicketUpdateDTO = {} as AntragTicketUpdateDTO;
      this.autoCreateDTO(antragTicketUpdateDTOtemp,this.antragTicketFormFields);
      antragTicketUpdateDTOtemp.geschwisterkind1Id = this.getFormItem('geschwisterkind1')?.value?.length !== 0 ? this.getFormItem('geschwisterkind1').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind2Id = this.getFormItem('geschwisterkind2')?.value?.length !== 0 ? this.getFormItem('geschwisterkind2').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind3Id = this.getFormItem('geschwisterkind3')?.value?.length !== 0 ? this.getFormItem('geschwisterkind3').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind4Id = this.getFormItem('geschwisterkind4')?.value?.length !== 0 ? this.getFormItem('geschwisterkind4').value : null;
      antragTicketUpdateDTOtemp.geschwisterkind5Id = this.getFormItem('geschwisterkind5')?.value?.length !== 0 ? this.getFormItem('geschwisterkind5').value : null;
      antragTicketUpdateDTOtemp.referenzFuerZahlungen = this.getFormItem('referenzFuerZahlungen').value;
      //add first day to Month from ('2022-08') to ('2022-08-01')
      if(this.isMonthSelection){
        antragTicketUpdateDTOtemp.aboGueltigkeit = this.getFormItem('aboGueltigkeit')?.value?.length !== 0 ? new Date(this.getFormItem('aboGueltigkeit').value.toString()+'-01') : null;
      }
      let antragTicketSepaUpdateDTOtemp : AntragTicketSepaUpdateDTO = {} as AntragTicketSepaUpdateDTO;
      this.autoCreateDTO(antragTicketSepaUpdateDTOtemp, this.antragTicketSepaFormFields);
      antragTicketUpdateDTOtemp.status = targetStatus;
      antragTicketSepaUpdateDTOtemp.isAntragstellerMandatsgeber = this.getFormItem('isAntragstellerNotMandatsgeber')?.value?.length !== 0 ? !this.getFormItem('isAntragstellerNotMandatsgeber').value : undefined;
      antragTicketUpdateDTOtemp.antragTicketSepa = antragTicketSepaUpdateDTOtemp;
      if(this.getFormItem('schuelerId').value!==-1){ // -1 == mich selbst
        antragTicketUpdateDTOtemp.schuelerId = this.getFormItem('schuelerId').value;
      } else {
        antragTicketUpdateDTOtemp.schuelerId = null;
      }

      let praktikumsvertrag: File = undefined;
      if (!(!this.getFormItem('praktikumsvertrag')) && !(!this.getFormItem('praktikumsvertragFile')?.value)) {
        praktikumsvertrag = this.getFormItem('praktikumsvertragFile').value;
      }
      let sonderfallNachweis: File = undefined;
      if (!(!this.getFormItem('sonderfallNachweis')) && !(!this.getFormItem('sonderfallNachweisFile')?.value)) {
        sonderfallNachweis = this.getFormItem('sonderfallNachweisFile').value;
      }
      let bezugNachweis: File = undefined;
      if (!(!this.getFormItem('bezugNachweis')) && !(!this.getFormItem('bezugNachweisFile')?.value)) {
        bezugNachweis = this.getFormItem('bezugNachweisFile').value;
      }

      this.antragTicketService.updateAntrag(parseInt(this.antragTicketId), antragTicketUpdateDTOtemp, praktikumsvertrag, sonderfallNachweis, bezugNachweis)
        .pipe(
          catchError((err) => {
            if (err.error.message == 'required_fields_not_present') {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden. Bitte füllen Sie alle Pflichtfelder aus.');
            } else {
              this.customToastService.showError('Der Antrag konnte nicht gespeichert werden.');
            }
            resolve(false);
            return EMPTY;
          })
        )
        .subscribe((data: AntragTicketDTO) => {
          this.antragTicket = {...data};
          resolve(true);
        });
    });
  }

  //DECISION EINREICHEN
  showDecisionModal(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.textbausteinService.printTextbausteinByKey( KeyString.ANTRAGTICKET_EINREICHEN_MODAL_TITEL,
      this.translateService.instant('MODAL.ANTRAG_EINREICHEN_MODAL_TITEL'));
    modalRef.componentInstance.text = this.textbausteinService.printTextbausteinByKey( KeyString.ANTRAGTICKET_EINREICHEN_MODAL_TEXT,
      this.translateService.instant('MODAL.ANTRAG_EINREICHEN_MODAL_TEXT'));
    modalRef.componentInstance.onYes = () => {
      this.spinner.show().catch(()=>{});
      if(!this.antragTicketId){
        this.createAntragTicket(targetStatus, saveMsg, goToPath);
      } else {
        this.updateAntragTicket(targetStatus, saveMsg, goToPath);
      }
    };
  }

  // SAVE
  saveOrUpdate(targetStatus: TicketStatus, saveMsg: string, goToPath: string){
    this.spinner.show().catch(()=>{});

    if (this.getFormItem('schwerbehinderung').value == null || this.getFormItem('schwerbehinderung').value == undefined){
      this.antragTicketForm.patchValue({schwerbehinderung: false});
    }
    if (this.getFormItem('datenschutz').value == null || this.getFormItem('datenschutz').value == undefined){
      this.antragTicketForm.patchValue({datenschutz: false} );
    }
    if (this.getFormItem('marketingfreigabe').value == null || this.getFormItem('marketingfreigabe').value == undefined){
      this.antragTicketForm.patchValue({marketingfreigabe: false} );
    }
    if (this.getFormItem('isAntragstellerNotMandatsgeber').value == null || this.getFormItem('isAntragstellerNotMandatsgeber').value == undefined){
      this.antragTicketForm.patchValue({isAntragstellerNotMandatsgeber: false} );
    }
    if(targetStatus == TicketStatus.EINGEREICHT){
      this.updateEverything();
      this.isSubmitted = true;
      this.applyValidators().then(()=>{
        this.spinner.hide().catch(()=>{});
        if (!this.antragTicketForm.valid) {
          this.customToastService.showError('Es sind nicht alle Felder korrekt ausgefüllt: ' + this.getNotValid());
        } else {
          this.showDecisionModal(targetStatus,saveMsg,goToPath);
        }
      }).catch(err=>console.log(err));
    } else if(targetStatus == TicketStatus.ENTWURF) {
      this.isSubmitted = false;
      this.clearAllValidators();
      if(!this.antragTicketId){
        this.createAntragTicket(targetStatus, saveMsg, goToPath);
      } else {
        this.updateAntragTicket(targetStatus, saveMsg, goToPath);
      }
    }
  }

  deleteDraft(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.textbausteinService.printTextbausteinByKey( KeyString.ENTWURF_LOESCHEN_MODAL_TITEL,
      this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_TITEL'));
    modalRef.componentInstance.text = this.textbausteinService.printTextbausteinByKey( KeyString.ENTWURF_LOESCHEN_MODAL_TEXT,
      this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_TEXT'));
    modalRef.componentInstance.onYes = () => {
      this.antragTicketService.deleteAntrag(parseInt(this.antragTicketId))
        .pipe(
          catchError(() => {
            this.customToastService.showError('Der Entwurf konnte nicht gelöscht werden.');
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.router.navigate(['/pages/ticketTabelle']).then(() => {
            this.customToastService.showSuccess('Der Entwurf wurde erfolgreich gelöscht.');
          }).catch(()=>{});
        });
    };
  }

  deleteNachweis(currentTarget: string){
    if( this.readonly || this.isInitializing || !this.antragTicketForm )
      return;
    switch (currentTarget) {
      case 'sonderfallNachweis': this.sonderfallNachweis = null; break;
      case 'praktikumsvertrag': this.praktikumsvertrag = null; break;
      case 'bezugNachweis': this.bezugNachweis = null; break;
      default: return;
    }
    this.antragTicketForm.get(currentTarget).patchValue(null,{emitEvent: false, onlySelf: true});
    this.antragTicketForm.get(currentTarget+'File').patchValue(undefined,{emitEvent: false, onlySelf: true});
    this.antragTicketForm.get(currentTarget+'Id').patchValue(null,{emitEvent: false, onlySelf: true});
    this.antragTicketForm.get(currentTarget).updateValueAndValidity();
  }

  checkIfVorgaengerExists(){

    // this method name is only for congruence with AntragTicket SBtrue
    // however, with AntragTicket SBfalse there is no point in checking for Vorgänger
    // so this step is omitted here, and method loads either schueler data or benutzer data

    if( this.readonly || this.isInitializing || !this.antragTicketForm )
      return;

    this.resetFormValues(['name','vorname','strasse','plz','ort',
      'land','adresszusatz','geburtsdatum','isUnderAge','email','emailSchueler', 'telefonnummer',
      'geschlecht','schule','typ',
      'geschwisterkind1','geschwisterkind2','geschwisterkind3','geschwisterkind4','geschwisterkind5']);
    this.resetFormValidators(['name','vorname','strasse','plz','ort',
      'land','adresszusatz','geburtsdatum','isUnderAge','email','emailSchueler','telefonnummer',
      'geschlecht','schule','typ',
      'geschwisterkind1','geschwisterkind2','geschwisterkind3','geschwisterkind4','geschwisterkind5']);

    const schuelerId = this.getFormItem('schuelerId')?.value;
    // schuelerId == null : nichts ausgewählt
    // schuelerId == -1: "mich selbst" ausgewählt = benutzer, aber benutzerDto hat keine schuelerId

    if( !!schuelerId && schuelerId!==-1 ){
      this.schuelerService.getSchueler(this.getFormItem('schuelerId')?.value).subscribe(result => {

        const schule = getObjIfExistsInListByAttribute(result.schule,this.schuleList,'name') as SchuleDTO

        this.antragTicketForm.patchValue({
          name: result.name || null,
          vorname: result.vorname || null,
          strasse: result.strasse || null,
          plz: result.plz || null,
          ort: result.ort || null,
          land: result.land || 'DEUTSCHLAND',
          adresszusatz: result.adresszusatz || null,
          geburtsdatum: result.geburtsdatum || null,
          geschlecht: result.geschlecht || null,
          schule: schule?.name || null,
          typ: 'NEUANTRAG',
          schuelernummer: result.schuelernummer || null,
        });

        this.schuelerDeleted = result.deleted;
        this.setButtonVisibility();

      });
    } else {

      //"mich selbst"

      const schule = getObjIfExistsInListByAttribute(this.benutzer.schule,this.schuleList,'name') as SchuleDTO

      this.antragTicketForm.patchValue({
        name: this.benutzer.name || null,
        vorname: this.benutzer.vorname || null,
        strasse: this.benutzer.strasse || null,
        plz: this.benutzer.plz || null,
        ort: this.benutzer.ort || null,
        land: this.benutzer.land || 'DEUTSCHLAND',
        adresszusatz: this.benutzer.adresszusatz || null,
        geburtsdatum: this.benutzer.geburtsdatum || null,
        email: this.benutzer.email || null,
        schwerbehinderung: null,
        geschlecht: this.benutzer.geschlecht || null,
        schule: schule?.name || null,
        typ: Typ.NEUANTRAG,
        schuelernummer: this.benutzer.schuelernummer || null,
      });

      this.schuelerDeleted = false;
      this.setButtonVisibility();

    }
  }

  getFormItem(s: string) {
    return this.antragTicketForm?.get(s);
  }

  sanitize(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  previewImg(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.image = src;
  }

  previewPDF(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.width = 500;
    modalRef.componentInstance.height = 600;
    modalRef.componentInstance.pdf = src;
  }

  handleNachweis({currentTarget}){
    if(currentTarget.files != null && currentTarget.files.length > 0) {
      let file : File = currentTarget.files[0];
      this.imageHelper.handleNachweisFile(file)
        .then((processed)=>{
          let fileFormControlName : string = undefined;
          switch (currentTarget.id){
            case 'input-praktikumsvertrag':
              this.praktikumsvertragIsPDF = processed.isPDF;
              this.praktikumsvertrag = processed.image;
              fileFormControlName = 'praktikumsvertragFile';
              break;
            case 'input-sonderfallNachweis':
              this.sonderfallNachweisIsPDF = processed.isPDF;
              this.sonderfallNachweis = processed.image;
              fileFormControlName = 'sonderfallNachweisFile';
              break;
            case 'input-bezugNachweis':
              this.bezugNachweisIsPDF = processed.isPDF;
              this.bezugNachweis = processed.image;
              fileFormControlName = 'bezugNachweisFile';
              break;
            default:
              console.log('Error, formControlId not recognized.',currentTarget.id);
              break;
          }
          if(processed.error) {
            this.customToastService.showError(processed.error);
            this.getFormItem(fileFormControlName).patchValue(undefined);
            return;
          }
          this.getFormItem(fileFormControlName).patchValue(file);
        }).catch(()=>{});
    } else {
      this.deleteNachweis((currentTarget.id).split('input-')[1])
    }
  }

  dynamicWidth(field: string){
    const len = this.getFormItem(field)?.value? this.getFormItem(field).value.length + 1 : 0;
    return 'width:'+len+'ch;'
  }

  setGeschwisterkind(nr: number){
    let varName = 'geschwisterkind'+nr;
    let schuelerIdgeschwister = this.getFormItem(varName)?.value;
    if(!schuelerIdgeschwister)
      return;
    let numbersToCompare = [1,2,3,4,5];
    numbersToCompare.splice(numbersToCompare.indexOf(nr),1);
    let selectedSchueler : SchuelerDTO;

    //case first edit
    if(this.getFormItem('schuelerId')?.value?.length > 0){
      let schuelerIdsToCompare = [this.getFormItem('schuelerId')?.value];
      numbersToCompare.forEach(num=>
        schuelerIdsToCompare = [...schuelerIdsToCompare,this.getFormItem('geschwisterkind'+num).value]);
      if(schuelerIdsToCompare.indexOf(schuelerIdgeschwister)>-1){
        this.antragTicketForm.patchValue({varName : ''});
        this.customToastService.showError("Sie haben diese Person bereits ausgewählt.");
        return;
      }
    } else { //Case load entwurf
      //schuelerIdsToCompare == null
      let schuelerToCompare= [(this.getFormItem('name')?.value + this.getFormItem('vorname')?.value +  this.getFormItem('geburtsdatum')?.value + this.getFormItem('plz')?.value + this.getFormItem('strasse')?.value+ this.getFormItem('ort')?.value)];
      numbersToCompare.forEach(num=>
        schuelerToCompare = [...schuelerToCompare,(this.getFormItem('geschwisterkind'+num+'name')?.value + this.getFormItem('geschwisterkind'+num+'vorname')?.value + this.getFormItem('geschwisterkind'+num+'geburtsdatum')?.value+ this.getFormItem('geschwisterkind'+num+'plz')?.value + this.getFormItem('geschwisterkind'+num+'strasse')?.value + this.getFormItem('geschwisterkind'+num+'ort')?.value)]);

      this.schuelerService.getSchueler(schuelerIdgeschwister).subscribe(
        (schueler) => {
          if (schueler) {
            selectedSchueler = schueler;
            let schuelerCompareStringGeschwister = schueler['name']+ schueler['vorname']+schueler['geburtsdatum']+schueler['plz']+schueler['strasse']+schueler['ort'];
            if(schuelerToCompare.indexOf(schuelerCompareStringGeschwister)>-1){
              this.antragTicketForm.patchValue({varName : ''});
              this.customToastService.showError("Sie haben diese Person bereits ausgewählt!");
              return;
            }
          }
        });
    }

    if(selectedSchueler) {
      this.patchSchuelerFields(selectedSchueler, nr);
    } else {
      this.schuelerService.getSchueler(schuelerIdgeschwister).subscribe(
        (schueler) => {
          this.patchSchuelerFields(schueler, nr);
        }
      )
    }
  }

  private patchSchuelerFields(schueler: SchuelerDTO, nr: number) {
    let varName = 'geschwisterkind'+nr;
    if (schueler) {
      let fieldsToPatch = ['name', 'vorname', 'geburtsdatum', 'strasse', 'plz', 'ort'];
      fieldsToPatch.forEach((field) => {
        this.antragTicketForm.patchValue({[varName + field]: schueler[field]});
      });
      this.antragTicketForm.patchValue({[varName + 'schule']: !!schueler['schule'] ? schueler['schule']['name'] : 'Keine'});
    }
  }

  initEmptyForm(){
    this.antragTicketForm = this.fb.group({
      name: undefined,
      vorname: undefined,
      strasse: undefined,
      plz: undefined,
      ort: undefined,
      geburtsdatum: undefined,
      email: undefined,
      emailSchueler: undefined,
      telefonnummer: undefined,
      schwerbehinderung: undefined,
      aboGueltigkeit: undefined,
      schule: undefined,
      isBerufskolleg: undefined,
      schuljahr: undefined,
      klasse: undefined,
      bildungsgangdatum:undefined,
      fachbereich:undefined,
      ticket: undefined,
      geschlecht: undefined,
      einstieghaltestelle: undefined,
      ausstieghaltestelleHinweg: undefined,
      referenzFuerZahlungen: undefined,
      abonnementnummerAntrag: undefined,
      bemerkungAntragsteller: undefined,
      einverstaendnisErklaerung: undefined,

      geschwisterkind1: undefined,
      geschwisterkind2: undefined,
      geschwisterkind3: undefined,
      geschwisterkind4: undefined,
      geschwisterkind5: undefined,

      geschwisterkind1name: undefined,
      geschwisterkind1vorname: undefined,
      geschwisterkind1geburtsdatum: undefined,
      geschwisterkind1strasse: undefined,
      geschwisterkind1plz: undefined,
      geschwisterkind1ort: undefined,
      geschwisterkind1schule: undefined,

      geschwisterkind2name: undefined,
      geschwisterkind2vorname: undefined,
      geschwisterkind2geburtsdatum: undefined,
      geschwisterkind2strasse: undefined,
      geschwisterkind2plz: undefined,
      geschwisterkind2ort: undefined,
      geschwisterkind2schule: undefined,

      geschwisterkind3name: undefined,
      geschwisterkind3vorname: undefined,
      geschwisterkind3geburtsdatum: undefined,
      geschwisterkind3strasse: undefined,
      geschwisterkind3plz: undefined,
      geschwisterkind3ort: undefined,
      geschwisterkind3schule: undefined,

      geschwisterkind4name: undefined,
      geschwisterkind4vorname: undefined,
      geschwisterkind4geburtsdatum: undefined,
      geschwisterkind4strasse: undefined,
      geschwisterkind4plz: undefined,
      geschwisterkind4ort: undefined,
      geschwisterkind4schule: undefined,

      geschwisterkind5name: undefined,
      geschwisterkind5vorname: undefined,
      geschwisterkind5geburtsdatum: undefined,
      geschwisterkind5strasse: undefined,
      geschwisterkind5plz: undefined,
      geschwisterkind5ort: undefined,
      geschwisterkind5schule: undefined,

      schuelernummer: undefined,
      richtigkeitDerAngaben: undefined,
      typ: undefined,
      schuelerId: undefined,
      marketingfreigabe: undefined,

      land: 'DEUTSCHLAND',
      vormundName: undefined,
      vormundVorname: undefined,
      isVormundadresseAbweichend:undefined,
      vormundStrasse: undefined,
      vormundOrt: undefined,
      vormundPlz: undefined,
      vormundAdresszusatz: undefined,
      vormundLand: undefined,
      vormundGeburtsdatum: undefined,
      vormundTelefonnummer: undefined,
      vormundEmail: undefined,
      vormundGeschlecht: undefined,
      wegtyp: undefined,
      bildungsgang: undefined,
      schultageJeWoche: undefined,
      praktikumHinweis: undefined,
      praktikumStelle: undefined,
      praktikumStrasse: undefined,
      praktikumOrt: undefined,
      praktikumPlz: undefined,
      praktikumLand: undefined,
      praktikumAdresszusatz: undefined,
      praktikumStartdatum: undefined,
      praktikumEnddatum: undefined,
      praktikumstageJeWoche: undefined,
      sonderfallEnabled: undefined,
      sonderfall: undefined,
      sonderfallBegruendung: undefined,
      bezugAsylbewerberleistungsgesetz: undefined,
      adresszusatz: undefined,

      // files
      praktikumsvertrag: undefined,
      praktikumsvertragFile: undefined,
      praktikumsvertragId: undefined,
      sonderfallNachweis: undefined,
      sonderfallNachweisFile: undefined,
      sonderfallNachweisId: undefined,
      bezugNachweis: undefined,
      bezugNachweisFile: undefined,
      bezugNachweisId: undefined,

      // SEPA
      vornameSepa: undefined,
      nameSepa: undefined,
      strasseSepa: undefined,
      plzSepa: undefined,
      ortSepa: undefined,
      adresszusatzSepa: undefined,
      landSepa: undefined,
      emailSepa: undefined,
      telefonnummerSepa: undefined,
      geburtsdatumSepa: undefined,
      geschlechtSepa: undefined,
      iban: undefined,
      ibanSepa: undefined,
      isAntragstellerNotMandatsgeber: undefined,
      datenschutz: undefined,
      bicSepa: undefined,
      bic: undefined,
      nameKreditinstitut: undefined,
      nameKreditinstitutSepa: undefined,

      status: undefined
    });
  }

  correctLand(){
    let occurencesOfLand = ['land','vormundLand','praktikumLand','landSepa'];
    occurencesOfLand.forEach( (occ:string) => {
      let land = this.getFormItem(occ).value;
      if( !land || land.length<1 || (!(<any>Object).keys(Land).includes(land)&&!(<any>Object).keys(Land).includes(land.toUpperCase())) ){
        this.antragTicketForm.patchValue({[occ]: 'DEUTSCHLAND'});
      }
    });
  }

  setPLZValidatorForControl(plzControlname:string) {
    if (!this.isSubmitted)
      return;
    this.getFormItem(plzControlname)?.setValidators([
      Validators.maxLength(this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value)),
      Validators.pattern(this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value))
    ]);
  }
  getMaxLength(plzControlname:string): number {
    return this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }
  getPattern(plzControlname:string): string {
    return this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }

  addFormalValidators(){
    if(this.effectivelyVisible?.includes('iban')) {
      this.getFormItem('iban')?.setValidators([ValidatorService.validateIban]);
      this.getFormItem('iban')?.updateValueAndValidity();
    }
    if(this.effectivelyVisible?.includes('ibanSepa')) {
      this.getFormItem('ibanSepa')?.setValidators([ValidatorService.validateIban]);
      this.getFormItem('ibanSepa')?.updateValueAndValidity();
    }
    if(this.effectivelyVisible?.includes('vormundGeburtsdatum')) {
      this.getFormItem('vormundGeburtsdatum')?.setValidators([createVormundAgeValidator()]);
      this.getFormItem('vormundGeburtsdatum')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('abonnementnummerAntrag') && this.moduleConfigService.isModuleEnabled('patrisExport')) { // add validators for abonnementnummerAntrag for patris export
      this.getFormItem('abonnementnummerAntrag')?.setValidators([Validators.pattern(this.validationHelper.abonummerPattern)]);
      this.getFormItem('abonnementnummerAntrag')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('strasse')) { // add validators for strasse and hausnummer
      this.getFormItem('strasse')?.setValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('strasse')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('vormundStrasse')) { // add validators for strasse and hausnummer
      this.getFormItem('vormundStrasse')?.setValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('vormundStrasse')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('praktikumStrasse')) { // add validators for strasse and hausnummer
      this.getFormItem('praktikumStrasse')?.setValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('praktikumStrasse')?.updateValueAndValidity();
    }
    if (this.effectivelyVisible?.includes('strasseSepa')) { // add validators for strasse and hausnummer
      this.getFormItem('strasseSepa')?.setValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
      this.getFormItem('strasseSepa')?.updateValueAndValidity();
    }
  }

  getNotValid() : string{
    let notValid: string = '';
    const controls = this.antragTicketForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        let temp = document.getElementById('sfklabel-'+name)
        notValid += ''+ (
          temp?.innerHTML.replace(''+PFLICHTFELDMARKER,'').trim()
          || this.fieldConfigService.getFieldLabel(name, this.fieldConfig, toTitlecase(name))) +', ';
      }
    }
    return notValid.substring(0, notValid.length-2);  // remove last comma
  }

  ngOnDestroy(){
    this.formItemSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.spinner.hide().catch(()=>{});
  }

  private initAboDatumGueltigAb() {
    this.isMonthSelection = this.moduleConfigService.isModuleEnabled("vertragsbeginnMonthly");
    this.minDateTicketGueltigAb = (new Date(new Date().getFullYear()-2,0,0)).toISOString().slice(0, 10);
    this.maxDateTicketGueltigAb =  new Date(new Date().getFullYear()+2,0,0).toISOString().slice(0, 10);
  }

  getTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey?
        this.translateService.instant(translateKey) : '') : '';
  }


  protected readonly KeyString = KeyString;
}
