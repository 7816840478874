import {Directive, ElementRef, forwardRef, HostListener, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DecimalPipe} from "@angular/common";

@Directive({
  selector: '[appGermanDecimal]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GermanDecimalDirective),
      multi: true
    },
    DecimalPipe
  ]
})
export class GermanDecimalDirective implements ControlValueAccessor {

  @Input() disabled = false;
  @Input() appGermanDecimal : number = 2;

  private _onChange: (value: any) => {};

  constructor(private _elementRef: ElementRef) { }

  @HostListener('input') onInput() {
    let value = this._elementRef.nativeElement.value;

    let checkResult = this.checkInput(value);
    this._elementRef.nativeElement.value = checkResult;

    // save number in international format as formcontrol.value
    let newValue = this.germanToInternational(checkResult);
    this._onChange(newValue);
  }

  private internationalToGerman(value: string) : string {
    return String(value).replace(/\./g,',')
  }

  private germanToInternational(value: string) : string {
    return String(value).replace(/,/g,'.')
  }

  // correction / limitation of user input
  private checkInput( value: any ) : string {
    let valStr = String(value);

    // delete anything that is not a number and not a comma
    valStr = valStr.replace(/[^0-9,]/g,'')

    // delete potential second comma and anything that comes behind it
    if(valStr.includes(',')){
      const valSplitted = valStr.split(',')
      // pad post-comma decimals to length specified in directive call [appGermanDecimal] = length
      return ''+valSplitted[0]+','+valSplitted[1].substring(0,this.appGermanDecimal)
    }

    return valStr
  }

  // interface methods forced by controlvalueaccessor interface

  // writes model (form) -> view (gui)
  // if triggered programmatically, i.e. via patch from DTO or by some calculation,
  // the value-digits might be longer than specified => round value and limit digits
  writeValue(value: string): void {
    if( !value || value == 'null' || value == 'undefined' ){
      return;
    }

    const floatVal = Number( (parseFloat(value)).toFixed(this.appGermanDecimal) );
    let valStr = this.internationalToGerman(''+floatVal);

    // in many browsers "toFixed" removes post-comma digits when their value is 0
    // so we add post-comma digits manually
    if(!valStr.includes(','))
      valStr += ',0';

    // pad post-comma digits to specified length
    const valSplit = valStr.split(',');
    let postComma = valSplit[1];
    while(postComma.length < this.appGermanDecimal)
      postComma += '0'

    this._elementRef.nativeElement.value = valSplit[0]+','+postComma;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // writes view (gui) -> model (form)
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

}
